import React from "react";
import { useTranslation } from "react-i18next";

const TimeFormateSelection = ({
  formik,
  isDisabled,
}: {
  formik: any;
  isDisabled: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-flex align-items-center">
        <input
          type="radio"
          name="timeFormat"
          value="Y"
          checked={formik.values.timeFormat === "12"}
          onChange={() => {
            formik.setValues({
              ...formik.values,
              timeFormat: "12",
            });
          }}
          className="mx-5"
          disabled={isDisabled}
        />{" "}
        <label htmlFor="time_format_12">
          {t("12")} {t("HOUR")}
        </label>
      </div>
      <div className="d-flex align-items-center">
        <input
          type="radio"
          name="timeFormat"
          checked={formik.values.timeFormat === "24"}
          onChange={() => {
            formik.setValues({
              ...formik.values,
              timeFormat: "24",
            });
          }}
          value="N"
          className="mx-5"
          disabled={isDisabled}
        />{" "}
        <label htmlFor="time_format_24">
          {" "}
          {t("24")} {t("HOUR")}
        </label>
      </div>
    </>
  );
};

export default TimeFormateSelection;
