import FieldPermissionGroup from "./FieldPermissionGroup";
import "assets/css/global.css";
import ProductDropdown from "components/forms/ProductDropdown";
import { useState } from "react";
const PermissionDrawer = () => {
  const fieldPermission = [
    {
      id: 0,
      fieldTitle: "Basic info",
      subField: ["Name", "Contact Num", "Address"],
    },
    {
      id: 1,
      fieldTitle: "Summary",
      subField: ["work", "About Me", "Job Description"],
    },
    {
      id: 2,
      fieldTitle: "Personal",
      subField: ["Myself", "Exit"],
    },
    {
      id: 3,
      fieldTitle: "Work experience",
      subField: ["Myself", "Exit"],
    },
    {
      id: 4,
      fieldTitle: "Education",
      subField: [],
    },
    {
      id: 5,
      fieldTitle: "Department",
      subField: [],
    },
  ];

  const [product, setProduct] = useState("");
  const [formField, setFormField] = useState("");
  return (
    <div
      id="n_one_drawer_FormPermissions"
      className="bg-body"
      data-kt-drawer="true"
      data-kt-drawer-name="FormPermissions"
      data-kt-drawer-activate="true"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'300px', 'md': '600px'}"
      data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#n_one_drawer_form_permissions_toggle"
      data-kt-drawer-close="#n_one_drawer_FormPermissions_close"
    >
      {" "}
      <div className="d-flex flex-column w-100 ">
        <div className="d-flex justify-content-between align-items-center border-bottom border-primary px-5">
          <div className="card-title">
            <div className="d-flex justify-content-center flex-column me-3 mt-2">
              <div className="fs-3 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">
                Permission
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div
              className="btn btn-sm btn-icon btn-active-light-primary"
              id="n_one_drawer_FormPermissions_close"
            >
              <i className="ki-duotone ki-cross fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between m-5">
          <div className=" d-flex align-items-center ">
            <span className="me-5 text-primary fs-4">Role:</span>
            <div className="w-150px">
              <ProductDropdown
                name="selectedProduct"
                value={product}
                onChange={(e) => {
                  setProduct(e.target.value);
                }}
                selectionOptions={[
                  { label: "Manager", value: "Manager" },
                  { label: "HR", value: "hr" },
                  { label: "Director", value: "Director" },
                ]}
                getSelectedOption={() => console.log("OnChange")}
              />
            </div>
          </div>
          <div className=" d-flex align-items-center ">
            <span className="me-5 text-primary fs-4">Form:</span>
            <div className="w-150px">
              <ProductDropdown
                name="selectedProduct"
                value={formField}
                onChange={(e) => {
                  setFormField(e.target.value);
                }}
                selectionOptions={[
                  { label: "Employee", value: "Employee" },
                  { label: "Asset", value: "Asset" },
                  { label: "Asset Type", value: "Asset Type" },
                  { label: "Candidate", value: "Candidate" },
                  { label: "Benefit", value: "Benefit" },
                ]}
                getSelectedOption={() => console.log("OnChange")}
              />
            </div>
          </div>
        </div>

        <div>
          <h4 className="mx-5 my-2">Field Permission</h4>
          <div
            className="d-flex justify-content-between w-100 px-5 fontSize-16"
            style={{
              borderTop: "1px solid #c9c9c9",
              borderBottom: "1px solid #c9c9c9",
            }}
          >
            <div className="w-25">Field Name</div>
            <div>View Permission</div>
            <div>Edit Permission</div>
          </div>
          {fieldPermission.map((data) => {
            return (
              <FieldPermissionGroup key={data.id} fieldPermission={data} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PermissionDrawer;
