import CardHeader from "components/cards/CardHeader";
import InputTextField from "components/forms/InputTextField";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { createUserRole } from "services/userManagementAPIs";
import { ValidateConstant, RolesConstant } from "constant/dashboardConstant";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";

import { object } from "yup";
import ErrorMessageBox from "components/base/ErrorMessageBox";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import { SelectionOption } from "helpers/models/auth_models";
import SearchableDropdown from "components/forms/SearchableDropdown";
import { areJSONsEqual } from "helpers/common";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import CardHeaderCancel from "components/forms/CardHeaderCancel";
import CardBottomSubmitBottom from "components/forms/CardBottomSubmitBottom";
interface Props {
  setPageRenderSate: Dispatch<SetStateAction<string>>;
  selectedProduct: string;
}

const CreateNewRole: FC<Props> = ({ setPageRenderSate, selectedProduct }) => {
  const { t } = useTranslation();
  const { userRole, fetchUserRoleHandler } = useUserManagementContext();
  const [roleNameErrors, setRoleNameErrors] = useState<string>("");
  const [requestErrors, setRequestErrors] = useState<string>("");
  const validationRules = useAuthValidationRules();
  const [userRolePrivileges, setUserRolePrivileges] = useState<any[]>([]);
  const [cloneRolePrivileges, setCloneRolePrivileges] = useState<any[]>([]);
  const [rolesOfProduct, setRolesOfProduct] = useState<SelectionOption[]>([]);
  const { fetchPrivilegeHandler, privileges } = useUserManagementContext();
  const [typeForCreateNewRole, setTypeForCreateNewRole] = useState<string>(
    ValidateConstant.MANUAL
  );

  const [filterUserRolesByProduct, setFilterUserRolesByProduct] = useState<
    any[]
  >([]);
  //Form State
  const [roleName, setRoleName] = useState("");
  const [selectedRoleForClone, setSelectedRoleForClone] = useState("");

  useEffect(() => {
    if (userRole.length > 0) {
      const filteredRoles = userRole.filter(
        (item) => item.productType.guid === selectedProduct
      );
      const rolesOfProductFormate: any = filteredRoles.map(
        (filteredItem: any) => ({
          label: filteredItem.displayValue,
          value: filteredItem.guid,
        })
      );
      setFilterUserRolesByProduct(filteredRoles);
      setRolesOfProduct(rolesOfProductFormate);
      fetchPrivilegeHandler();
    }
    // eslint-disable-next-line
  }, [selectedProduct]);

  useEffect(() => {
    if (privileges.length > 0 && filterUserRolesByProduct.length > 0) {
      const newPrivilege = privileges.find(
        (privilege) => privilege.displayValue === ValidateConstant.NONE
      );

      const userRolePrivilegesSet = filterUserRolesByProduct.find(
        (privilege) => privilege.role === RolesConstant.SALES_REPRESENTATIVE
      );

      const updatedUserRolePrivileges =
        userRolePrivilegesSet.userRolePrivileges.map((privilege: any) => ({
          ...privilege,
          privilege: newPrivilege,
        }));
      setUserRolePrivileges(updatedUserRolePrivileges);
    }
  }, [privileges, filterUserRolesByProduct]);

  useEffect(() => {
    if (filterUserRolesByProduct.length > 0) {
      const privilegeNew = filterUserRolesByProduct.find(
        (item) => item.guid === selectedRoleForClone
      );
      setUserRolePrivileges(privilegeNew.userRolePrivileges);
      setCloneRolePrivileges(privilegeNew.userRolePrivileges);
    }
    // eslint-disable-next-line
  }, [selectedRoleForClone]);

  const Schema = object().shape({
    roleName: validationRules.roleNameValidationSchema,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRoleName(value);
    Schema.validateAt(name, { roleName: value })
      .then(() => {
        setRoleNameErrors("");
      })
      .catch((error) => {
        setRoleNameErrors(error.message);
      });
  };

  const createUserRoleHandle = async () => {
    const newUserRolesPrivileges = userRolePrivileges.map((item) => {
      return {
        objectOrActionGuid: item.objectOrAction.guid,
        privilegeGuid: item.privilege.guid,
      };
    });
    let requestDataForCreateRole = {};
    if (
      typeForCreateNewRole === ValidateConstant.CLONE &&
      selectedRoleForClone.length > 0
    ) {
      requestDataForCreateRole = {
        roleName: roleName,
        cloneRoleGuid: selectedRoleForClone,
      };
    } else {
      requestDataForCreateRole = {
        roleName: roleName,
        rolePrivileges: newUserRolesPrivileges,
      };
    }

    try {
      await createUserRole(requestDataForCreateRole)
        .then((response) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            setRequestErrors(response.data.statusDesc);
          } else {
            setPageRenderSate(ValidateConstant.INDEX);
            fetchUserRoleHandler();
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  const [cloneRoleGuidErrors, setCloneRoleGuidErrors] = useState<string>("");

  const handleSubmitForm = async () => {
    try {
      await Schema.validate({
        cloneRoleGuid: selectedRoleForClone,
        roleName: roleName,
      });

      setRoleNameErrors("");
      setCloneRoleGuidErrors("");

      createUserRoleHandle();
    } catch (error: any) {
      if (error.path === "roleName") {
        setRoleNameErrors(error.message);
      }

      if (error.path === "cloneRoleGuid") {
        setCloneRoleGuidErrors(error.message);
      }
    }
  };

  const handlePrivilege = (
    userRolePrivilegesItem: any,
    currentPrivilege: any
  ) => {
    const updatedPrivileges = userRolePrivileges.map((privilege) => {
      if (privilege.guid === userRolePrivilegesItem.guid) {
        return {
          ...privilege,
          privilege: currentPrivilege,
        };
      }
      return privilege;
    });
    setUserRolePrivileges(updatedPrivileges);
    if (areJSONsEqual(cloneRolePrivileges, updatedPrivileges)) {
      setTypeForCreateNewRole(ValidateConstant.CLONE);
    } else {
      setTypeForCreateNewRole(ValidateConstant.MANUAL);
    }
  };

  const findRolePrivilegesWithGuid = (guid: string): any => {
    try {
      return userRolePrivileges.find((privilege) => privilege.guid === guid)
        ?.privilege;
    } catch (error: any) {
      console.error(error);
    }
  };

  const findDeletePrivilege = (guid: string): boolean => {
    const currentPrivilege = findRolePrivilegesWithGuid(guid);

    if (currentPrivilege.displayValue) {
      return currentPrivilege.displayValue === ValidateConstant.DELETE;
    } else {
      return false;
    }
  };

  const findCreatePrivilege = (guid: string): boolean => {
    const currentPrivilege = findRolePrivilegesWithGuid(guid);

    if (currentPrivilege.displayValue) {
      return (
        currentPrivilege.displayValue === ValidateConstant.DELETE ||
        currentPrivilege.displayValue === ValidateConstant.CREATE
      );
    } else {
      return false;
    }
  };

  const findModifyPrivilege = (guid: string): boolean => {
    const currentPrivilege = findRolePrivilegesWithGuid(guid);

    if (currentPrivilege.displayValue) {
      return (
        currentPrivilege.displayValue === ValidateConstant.DELETE ||
        currentPrivilege.displayValue === ValidateConstant.CREATE ||
        currentPrivilege.displayValue === ValidateConstant.MODIFY
      );
    } else {
      return false;
    }
  };

  const findReadPrivilege = (guid: string): boolean => {
    const currentPrivilege = findRolePrivilegesWithGuid(guid);
    if (currentPrivilege.displayValue) {
      return (
        currentPrivilege.displayValue === ValidateConstant.DELETE ||
        currentPrivilege.displayValue === ValidateConstant.CREATE ||
        currentPrivilege.displayValue === ValidateConstant.MODIFY ||
        currentPrivilege.displayValue === ValidateConstant.READ
      );
    } else {
      return false;
    }
  };

  const findNonePrivilege = (guid: string): boolean => {
    const currentPrivilege = findRolePrivilegesWithGuid(guid);

    if (currentPrivilege.displayValue) {
      return currentPrivilege.displayValue === ValidateConstant.NONE;
    } else {
      return false;
    }
  };

  const readChecker = (e: any, item: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.READ)
      );
    } else {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.NONE)
      );
    }
  };

  const modifyChecker = (e: any, item: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.MODIFY)
      );
    } else {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.READ)
      );
    }
  };

  const deleteChecker = (e: any, item: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.DELETE)
      );
    } else {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.CREATE)
      );
    }
  };
  const createChecker = (e: any, item: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.CREATE)
      );
    } else {
      handlePrivilege(
        item,
        privileges.find((obj) => obj.displayValue === ValidateConstant.MODIFY)
      );
    }
  };

  return (
    <form>
      <CardHeader>
        <CardHeaderCancel
          title={t("ADD.ROLE")}
          setPageRenderSateFun={() => setPageRenderSate(ValidateConstant.INDEX)}
        />
      </CardHeader>

      <br></br>
      {requestErrors.length > 0 && <ErrorMessageBox massage={requestErrors} />}
      <div className="row my-4  fs-6  ">
        <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex align-items-center">
          {t("ROLE.NAME")}
        </div>
        <div className="col-6">
          <InputTextField
            placeholder={t("ROLE.NAME")}
            id="roleName"
            name="roleName"
            type="text"
            value={roleName}
            onBlur={(e) => {
              handleChange(e);
            }}
            onChange={(e) => {
              handleChange(e);
            }}
            error={Boolean(roleNameErrors)}
            helperText={roleNameErrors}
          />
        </div>
      </div>
      <div className="text-muted d-flex mb-10 ">{t("PRIVILEGE")}</div>
      <div className="row my-4  fs-6  ">
        <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex align-items-center">
          {t("CREATE.NEW.ROLE")}
        </div>
        <div className="col-6 d-flex align-content-center">
          <input
            type="radio"
            name="typeForCreateNewRole"
            value={ValidateConstant.CLONE}
            checked={typeForCreateNewRole === ValidateConstant.CLONE}
            onChange={() => {
              setTypeForCreateNewRole(ValidateConstant.CLONE);
            }}
            className="mx-5 type-create-role"
          />{" "}
          {t("CLONE")}
          <input
            type="radio"
            id="mj-3"
            name="typeForCreateNewRole"
            checked={typeForCreateNewRole === ValidateConstant.MANUAL}
            onChange={() => {
              setTypeForCreateNewRole(ValidateConstant.MANUAL);
            }}
            value="N"
            className="mx-5 type-create-role"
          />{" "}
          {t("MANUAL")}
        </div>
      </div>
      {typeForCreateNewRole === ValidateConstant.CLONE && (
        <div className="row my-4  fs-6  ">
          <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex align-items-center">
            {t("SELECT.ROLE")}
          </div>
          <div className="col-6">
            <SearchableDropdown
              labelClass="required"
              name="rolesOfProduct"
              placeholder={t("SELECT.ROLE")}
              value={selectedRoleForClone}
              onChange={(event) => {
                setSelectedRoleForClone(event.target.value);
              }}
              selectionOptions={rolesOfProduct}
              error={Boolean(cloneRoleGuidErrors)}
              helperText={cloneRoleGuidErrors}
            />
          </div>
        </div>
      )}
      {userRolePrivileges ? (
        <>
          <div className="text-muted d-flex mb-10 ">{t("PRIVILEGE")}</div>
          {userRolePrivileges.map((item: any) => {
            return (
              <div key={item.guid} className="row my-4  fs-6  ">
                <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex   align-items-center">
                  {item.objectOrAction.displayValue}
                </div>
                <div className="col-9">
                  <div className="d-flex">
                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid ? findNonePrivilege(item.guid) : false
                        }
                        onChange={() => {
                          handlePrivilege(
                            item,
                            privileges.find(
                              (obj) =>
                                obj.displayValue === ValidateConstant.NONE
                            )
                          );
                        }}
                      />
                      <span className="form-check-label">{t("NONE")}</span>
                    </label>

                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid ? findReadPrivilege(item.guid) : false
                        }
                        onChange={(e) => {
                          readChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("READ")}</span>
                    </label>
                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid ? findModifyPrivilege(item.guid) : false
                        }
                        onChange={(e) => {
                          modifyChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("MODIFY")}</span>
                    </label>

                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid ? findCreatePrivilege(item.guid) : false
                        }
                        onChange={(e) => {
                          createChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("CREATE")}</span>
                    </label>

                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid ? findDeletePrivilege(item.guid) : false
                        }
                        onChange={(e) => {
                          deleteChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("DELETE")}</span>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="fs-6  text-muted">
          {t("messages:NO.PRIVILEGE.FOUND")}
        </div>
      )}
      <CardBottomSubmitBottom
        label={t("ADD.ROLE")}
        isError={Boolean(roleNameErrors)}
        handleSubmit={() => handleSubmitForm()}
      />
    </form>
  );
};

export default CreateNewRole;
