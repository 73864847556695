import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import AuthLayout from "../layout/AuthLayout";
import { Registration } from "../pages/auth/Registration";
import SendActivationLink from "pages/auth/SendActivationLink";
import ForgotPassword from "pages/auth/forgot-password";
import SendForgotPasswordLink from "pages/auth/forgot-password/SendForgotPasswordLink";
import SetNewPassword from "pages/auth/forgot-password/SetNewPassword";
import NotFound from "pages/error-page/NotFound";
import EmailVerified from "pages/auth/EmailVerified";
import { privateRoutesArray } from "constant/route/privateRoutes";
import { authRoutes } from "constant/route/authRoutes";
import LoginRedirect from "pages/auth/LoginRedirect";
import { useEffect } from "react";
import { useAuth } from "hooks‬/Auth";

const AuthRoutes = () => {
  const location = useLocation();
  const { systemLogout } = useAuth();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get("error");
    const errorDescription = queryParams.get("error_description");

    if (error === "access_denied" && errorDescription) {
      window.alert(decodeURIComponent(errorDescription));
      systemLogout();
    }
    // eslint-disable-next-line
  }, [location.search]);
  const queryParams = new URLSearchParams(location.search);
  const error = queryParams.get("error");

  return (
    <Routes>
      {!error && <Route index element={<Navigate to={authRoutes.login} />} />}
      <Route index element={<></>} />
      <Route path={authRoutes.login} element={<LoginRedirect />} />
      <Route element={<AuthLayout />}>
        <Route path={authRoutes.registration} element={<Registration />} />
        <Route
          path={authRoutes.emailVerification}
          element={<SendActivationLink />}
        />
        <Route path={authRoutes.forgotPassword} element={<ForgotPassword />} />
        <Route
          path={authRoutes.forgotLinkSent}
          element={<SendForgotPasswordLink />}
        />
        <Route path={authRoutes.resetPassword} element={<SetNewPassword />} />
        <Route path={authRoutes.emailVerified} element={<EmailVerified />} />
      </Route>

      {privateRoutesArray.map((path) => (
        <Route
          path={path}
          element={<Navigate to={authRoutes.login} />}
          key={path}
        />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { AuthRoutes };
