import clsx from "clsx";
import { KTIcon } from "../../helpers";
import { HeaderUserMenu } from "./header-menus/HeaderUserMenu";
import { useLayout } from "../../layout/core";
import profilePic from "../../assets/images/profilePic.jpg";
import { ThemeModeType, useThemeMode } from "hooks‬/theme";
import { useEffect } from "react";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import { updateSystemModeUserPreferences } from "services/userManagementAPIs";
import {
  colorDensityDetector,
  getPrimaryColor,
  updateColors,
} from "components/theme/ThemeHelpers";
const itemClass = "ms-1 ms-lg-3";
const userAvatarClass = "symbol-35px symbol-md-40px";
const btnIconClass = "fs-1";

const RightHeaderContainer = () => {
  const { mode, updateMode, updateMenuMode } = useThemeMode();
  const { fetchUserPreferencesHandler, userPreference, setUserPreference } =
    useUserManagementContext();
  // const { userPreference, setUserPreference } = useUserManagementContext();
  const updateSystemUserPreferencesHandler = async (_mode: string) => {
    try {
      await updateSystemModeUserPreferences(_mode)
        .then(() => {
          setUserPreference((previousData) => ({
            ...previousData,
            systemMode: _mode,
          }));
        })
        .catch((error: any) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const switchMode = (_mode: ThemeModeType) => {
    updateMenuMode(_mode);
    updateMode(_mode);
    colorDensityDetector(getPrimaryColor(), _mode);
    updateSystemUserPreferencesHandler(_mode);
    if (userPreference.themeColor) {
      if (userPreference.themeColor.startsWith("#")) {
        updateColors(userPreference.themeColor);
      } else {
        updateColors("#" + userPreference.themeColor);
      }
    }
  };

  useEffect(() => {
    fetchUserPreferencesHandler();
    // eslint-disable-next-line
  }, []);

  const { config } = useLayout();
  return (
    <div className="app-navbar flex-shrink-0">
      {mode === "light" ? (
        <div
          className={clsx("app-navbar-item ", itemClass)}
          onClick={() => {
            switchMode("dark");
          }}
        >
          <i
            className="ki-outline ki-moon text-primary-white pointer"
            style={{ fontSize: "22px" }}
          ></i>
        </div>
      ) : (
        <div
          className={clsx("app-navbar-item ", itemClass)}
          onClick={() => {
            switchMode("light");
          }}
        >
          <i
            className="ki-outline ki-sun text-primary-white pointer"
            style={{ fontSize: "24px" }}
          ></i>
        </div>
      )}

      <div className={clsx("app-navbar-item ", itemClass)}>
        <i
          className="ki-outline ki-notification text-primary-white pointer"
          style={{ fontSize: "24px" }}
        ></i>
      </div>

      <div className={clsx("app-navbar-item", itemClass)}>
        <div
          className={clsx("cursor-pointer symbol", userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
        >
          <img className="rounded-circle" src={profilePic} alt="" />
        </div>
        <HeaderUserMenu />
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div
          className="app-navbar-item d-lg-none ms-2 me-n3"
          title="Show header menu"
        >
          <div
            className="btn btn-icon btn-active-color-primary w-35px h-35px"
            id="n_one_app_header_menu_toggle"
          >
            <KTIcon iconName="text-align-left" className={btnIconClass} />
          </div>
        </div>
      )}
    </div>
  );
};

export { RightHeaderContainer };
