import React, { useEffect, useState } from "react";
import EmailVerifiedIcon from "assets/images/EmailVerifiedIcon.svg";
import Button from "components/base/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN_PAGE_REDIRECTOR_COUNTDOWN } from "constant/commonConstant";
import { useTranslation } from "react-i18next";

const EmailVerified = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const success = queryParams.get("success");

  const [seconds, setSeconds] = useState(LOGIN_PAGE_REDIRECTOR_COUNTDOWN); // Timer starts at 10 seconds

  const loginPageRedirector = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (!success) {
      navigate("/login");
    }
  }, [success, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        navigate("/login");
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds, navigate]);

  return (
    <div className="m-auto mt-10">
      <div className="text-center my-10">
        <img
          className="w-150px h-150px"
          src={EmailVerifiedIcon}
          alt="email-verified"
        />
      </div>
      <h1 className="text-center mt-5">
        {t("messages:EMAIL.SUCCESSFULLY.VERIFIED")}
      </h1>
      <h4 className="text-center fw-normal">
        {t("messages:REDIRECT.LOGIN.MSG")}{" "}
        <span className="text-danger">{seconds}</span> {t("SECONDS")}.{" "}
        {t("messages:IF.NOT.CLICKED.CONTINUE")}
      </h4>
      <div className="text-center mt-10">
        <Button type="button" onClick={loginPageRedirector}>
          {t("CONTINUE")}
        </Button>
      </div>
    </div>
  );
};

export default EmailVerified;
