import React from "react";
interface Props {
  title: string;
  value: string | undefined;
}
const ExpandableItem: React.FC<Props> = ({ title, value }) => {
  return (
    <div className="col-6">
      <div className="row">
        <div className="col-6 text-start text-muted fw-bold fs-7 text-uppercase d-flex justify-content-between">
          <span> {title}</span> <span> :</span>
        </div>
        <div className="col-6">{value ?? "-"}</div>
      </div>
    </div>
  );
};

export default ExpandableItem;
