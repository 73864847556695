import React, { useState, useEffect } from "react";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import Breadcrumbs from "components/base/breadcrumbs";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import SearchableDropdown from "components/forms/SearchableDropdown";
import date_format from "constant/json/date_format.json";
import { SelectionOption } from "helpers/models/auth_models";
import moment from "moment-timezone";
import { putCompanyPreferences } from "services/companyManagementRequests";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import {
  initCompanyPreferenceValue,
  useCompanyManagementContext,
} from "hooks‬/dashoard/CompanyManagementContext";
import CardHeader from "components/cards/CardHeader";
import {
  getLanguageList,
  getTimeZonesList,
} from "helpers/common/genericFunction";
import CardHeaderEditSave from "components/forms/CardHeaderEditSave";
import TimeFormateSelection from "components/forms/TimeFormateSelection";

const Index = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const date = new Date();
  const dateFormate = date_format.map((items: any) => ({
    label: items.label + " (" + moment(date).format(items.value) + ")",
    value: items.value,
  }));
  const [languagesList, setLanguagesList] = useState<SelectionOption[]>([]);
  const [timezonesList, setTimezonesList] = useState<SelectionOption[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const {
    companyPreference,
    setCompanyPreference,
    fetchCompanyPreferencesHandler,
  } = useCompanyManagementContext();

  const formik = useFormik({
    initialValues: initCompanyPreferenceValue,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoading(true);
      try {
        await putCompanyPreferences({
          dateFormat: values.dateFormat,
          timeFormat: values.timeFormat,
          displayLanguage: values.displayLanguage,
          displayTimezone: values.displayTimezone,
          systemMode: values.systemMode,
        })
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setErrors(response.data);
              setSubmitting(false);
              setLoading(false);
            } else {
              setCompanyPreference((previousData) => ({
                ...previousData,
                dateFormat: values.dateFormat,
                timeFormat: values.timeFormat,
                displayLanguage: values.displayLanguage,
                displayTimezone: values.displayTimezone,
                systemMode: values.systemMode,
              }));
              setLoading(false);
              setIsDisabled(true);
            }
          })
          .catch((error) => {
            setSubmitting(false);
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const cancelToUpdate = () => {
    setIsDisabled(true);
    formik.setValues({ ...companyPreference });
  };

  useEffect(() => {
    fetchCompanyPreferencesHandler();
    setTimezonesList(getTimeZonesList());
    setLanguagesList(getLanguageList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (languagesList.length > 0 && timezonesList.length > 0) {
      formik.setValues(companyPreference);
    }
    // eslint-disable-next-line
  }, [companyPreference, languagesList, timezonesList]);

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label="Dashboard" />
        <BreadcrumbDirective label={t("COMPANY.PREFERENCE")} />
      </Breadcrumbs>

      <div className="card p-10 my-4">
        <form onSubmit={formik.handleSubmit}>
          <CardHeader>
            <CardHeaderEditSave
              title={t("COMPANY.PREFERENCE")}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              cancelToUpdate={() => cancelToUpdate}
              loading={loading}
              formik={formik}
            />
          </CardHeader>
          <br></br>
          <h3>{t("DISPLAY.SETTINGS")}</h3>
          <div className="row my-4  mb-4 fs-6  ">
            <div className="col-3  fs-16 text-muted d-flex align-items-center">
              {t("DATE.FORMAT")}
            </div>
            <div className="col-6">
              <SearchableDropdown
                isDisable={isDisabled}
                placeholder={t("DATE.FORMAT")}
                name="dateFormat"
                value={formik.values.dateFormat}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={dateFormate}
                error={
                  formik.touched.dateFormat && Boolean(formik.errors.dateFormat)
                }
                helperText={
                  formik.touched.dateFormat && formik.errors.dateFormat
                }
              />
            </div>
          </div>
          <div className="row my-4   fs-6  ">
            <div className="col-3 mb-4 fs-16 text-muted d-flex   align-items-center">
              {t("TIME.FORMAT")}
            </div>
            <div className="col-6">
              <div className="d-flex gap-5">
                {isDisabled ? (
                  <div className="border-0 mb-4 form-control text-dark bg-field">
                    {formik.values.timeFormat} {t("HOUR")}
                  </div>
                ) : (
                  <TimeFormateSelection
                    formik={formik}
                    isDisabled={isDisabled}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row my-4  fs-6  ">
            <div className="col-3 mb-4 fs-16 text-muted d-flex   align-items-center">
              {t("LANGUAGE")}
            </div>
            <div className="col-6">
              <SearchableDropdown
                isDisable={isDisabled}
                placeholder={t("LANGUAGE")}
                name="displayLanguage"
                value={formik.values.displayLanguage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={languagesList}
                error={
                  formik.touched.displayLanguage &&
                  Boolean(formik.errors.displayLanguage)
                }
                helperText={
                  formik.touched.displayLanguage &&
                  formik.errors.displayLanguage
                }
              />
            </div>
          </div>

          <div className="row my-4  fs-6  ">
            <div className="col-3 mb-4 fs-16 text-muted d-flex    align-items-center">
              {t("SYSTEM.MODE")}
            </div>
            <div className="col-6">
              <SearchableDropdown
                isDisable={isDisabled}
                placeholder={t("SYSTEM.MODE")}
                name="systemMode"
                value={formik.values.systemMode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isVisibleSearchBox={false}
                selectionOptions={[
                  {
                    label: "Light",
                    value: "Light",
                  },
                  {
                    label: "Dark",
                    value: "Dark",
                  },
                ]}
                error={
                  formik.touched.displayLanguage &&
                  Boolean(formik.errors.displayLanguage)
                }
                helperText={
                  formik.touched.displayLanguage &&
                  formik.errors.displayLanguage
                }
              />
            </div>
          </div>
          <br />
          <h3>{t("LOCATION.SETTINGS")}</h3>
          <div className="row my-4  fs-6  ">
            <div className="col-3 mb-4 fs-16 text-muted d-flex   align-items-center">
              {t("TIME.ZONE")}
            </div>
            <div className="col-6">
              <SearchableDropdown
                isDisable={isDisabled}
                placeholder={t("TIME.ZONE")}
                name="displayTimezone"
                value={formik.values.displayTimezone || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                selectionOptions={timezonesList}
                error={
                  formik.touched.displayTimezone &&
                  Boolean(formik.errors.displayTimezone)
                }
                helperText={
                  formik.touched.displayTimezone &&
                  formik.errors.displayTimezone
                }
              />
            </div>
          </div>
        </form>
      </div>
      <br />
    </div>
  );
};

export default Index;
