import React from "react";

const ExpandableToggle = ({
  isExpanded,
  toggleExpand,
}: {
  isExpanded: boolean;
  toggleExpand: () => void;
}) => {
  return (
    <button
      className="fw-bold pointer fs-3 border-0 pad rounded"
      type="button"
      onClick={() => toggleExpand()}
    >
      {isExpanded ? "-" : "+"}
    </button>
  );
};

export default ExpandableToggle;
