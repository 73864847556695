import React, { Dispatch, SetStateAction, useState } from "react";
import { updateColors } from "./ThemeHelpers";
interface ThemeColorChangerProps {
  id_color_type: string;

  currentBaseColor: string | undefined;
  setCurrentBaseColor: Dispatch<SetStateAction<string | undefined>>;
}

const ThemeColorPicker: React.FC<ThemeColorChangerProps> = ({
  id_color_type,
  currentBaseColor,
  setCurrentBaseColor,
}) => {
  const [baseColor, setBaseColor] = useState<string>("#3498db");

  const handleBaseColorChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newBaseColor = event.target.value;
    setBaseColor(newBaseColor);
    updateColors(newBaseColor);
    setCurrentBaseColor(newBaseColor);
  };

  return (
    <div>
      <div className="pointer bg-light p-2 radius-12">
        <div
          style={{ textAlign: "center", margin: "10px" }}
          className="position-relative"
        >
          <label className="d-flex flex-column text-center align-items-center">
            <label
              htmlFor={id_color_type}
              style={{ top: "-10px" }}
              className="w-40px h-40px bg-light rounded-circle position-absolute z-3 d-flex align-items-center justify-content-center pointer"
            >
              {" "}
              <i
                className="fas fa-eye-dropper text-dark p-0"
                style={{ fontSize: "20px" }}
              ></i>
            </label>

            <input
              style={{ height: "20px", width: "20px" }}
              className="border-0 "
              type="color"
              id={id_color_type}
              value={baseColor}
              onChange={handleBaseColorChange}
            />
          </label>
        </div>{" "}
      </div>
    </div>
  );
};

export default ThemeColorPicker;
