import languages from "constant/json/languages.json";
import timezones from "constant/json/timezones.json";
import { SelectionOption } from "helpers/models/auth_models";

export const statusGetter = (status: string) => {
  let setStatus;
  if (status === "Active") {
    setStatus = "A";
  } else if (status === "Inactive") {
    setStatus = "I";
  } else if (status === "Delete") {
    setStatus = "D";
  }
  return setStatus;
};

export const getLanguageList = () => {
  return languages.reduce((newList: SelectionOption[], item) => {
    if (item.active === true) {
      newList.push({
        label: item.name,
        value: item.name,
      });
    }
    return newList;
  }, []);
};

export const getTimeZonesList = () => {
  return timezones.reduce((newList: SelectionOption[], item) => {
    if (item.isActive === true) {
      newList.push({
        label: item.displayName,
        value: item.value,
      });
    }
    return newList;
  }, []);
};

export const setCookie = (
  cname: string,
  cvalue: string,
  exdays?: number,
  domain?: string,
  secure?: boolean,
  sameSite?: "None" | "Lax" | "Strict"
): void => {
  domain = domain ?? "";
  secure = secure ?? true;
  sameSite = sameSite ?? "None";
  exdays = exdays ?? 365;

  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  const secureFlag = secure ? "secure" : "";
  const sameSiteFlag = `SameSite=${sameSite}`;

  document.cookie = `${cname}=${cvalue};${expires};path=/;${sameSiteFlag};${secureFlag};domain=${domain}`;
};

export const removeCookie = (cookieName: string, domain?: string): void => {
  const d = new Date();
  d.setTime(d.getTime() - 1);
  const expires = `expires=${d.toUTCString()}`;

  domain = domain ?? "";
  document.cookie = `${cookieName}=;${expires};path=/;domain=${domain};`;
};
