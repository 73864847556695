import { removeCookie } from "./common/genericFunction";

const AUTH_ACCESS_TOKEN_KEY = "EKCRC-AU-UTAETS";
const SUBSCRIPTION_ACCESS_TOKEN_KEY = "USRES-SUB-IXSZP";
export const ORG_KEY = "udo-oi-amdmdx";
const getAuthAccessToken = (): string => {
  if (!localStorage) {
    return "";
  }
  const token: string | null = localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
  if (!token) {
    return "";
  } else {
    return token;
  }
};

const setAuthAccessToken = (token: string) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, token);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const getSubscriptionAccessToken = (): string => {
  if (!localStorage) {
    return "";
  }
  const token: string | null = localStorage.getItem(
    SUBSCRIPTION_ACCESS_TOKEN_KEY
  );
  if (!token) {
    return "";
  } else {
    return token;
  }
};

const setSubscriptionAccessToken = (token: string) => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.setItem(SUBSCRIPTION_ACCESS_TOKEN_KEY, token);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }
  try {
    localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
    localStorage.removeItem(SUBSCRIPTION_ACCESS_TOKEN_KEY);
    removeCookie(ORG_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export {
  removeAuth,
  setAuthAccessToken,
  getAuthAccessToken,
  getSubscriptionAccessToken,
  setSubscriptionAccessToken,
};
