import React from 'react'

const ErrorMessageBox = ({massage}:{massage:any}) => {
  return (
    <div className="mb-lg-15 alert alert-danger">
    <div className="alert-text text-center font-weight-bold">
     {massage}
    </div>
  </div>
  )
}

export default ErrorMessageBox
