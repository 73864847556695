import Button from "components/base/button/Button";
import CardHeader from "components/cards/CardHeader";
import InputTextField from "components/forms/InputTextField";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  updateRoleName,
  updateRolePrivilege,
} from "services/userManagementAPIs";
import { ValidateConstant } from "constant/dashboardConstant";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";

import { object } from "yup";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import ErrorMessageBox from "components/base/ErrorMessageBox";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import {
  findCreatePrivilege,
  findDeletePrivilege,
  findModifyPrivilege,
  findNonePrivilege,
  findReadPrivilege,
} from "helpers/common/roleHelper";
import CardHeaderCancel from "components/forms/CardHeaderCancel";
import CardBottomSubmitBottom from "components/forms/CardBottomSubmitBottom";
interface Props {
  currentUpdatingData: any;
  setPageRenderSate: Dispatch<SetStateAction<string>>;
}

const UpdateRole: FC<Props> = ({ currentUpdatingData, setPageRenderSate }) => {
  const { t } = useTranslation();
  const [roleName, setRoleName] = useState("");
  const [roleNameErrors, setRoleNameErrors] = useState<string>("");
  const [requestErrors, setRequestErrors] = useState<string>("");
  const validationRules = useAuthValidationRules();
  const [userRolePrivileges, setUserRolePrivileges] = useState<any[]>([]);
  const { fetchPrivilegeHandler, privileges } = useUserManagementContext();

  useEffect(() => {
    setRoleName(currentUpdatingData.displayValue);
    setUserRolePrivileges(currentUpdatingData.userRolePrivileges);
    fetchPrivilegeHandler();
    // eslint-disable-next-line
  }, []);

  const selectAllPrivilegeHandler = (isChecked: boolean) => {
    if (isChecked) {
      const updatedPrivileges = userRolePrivileges.map((privilege) => {
        return {
          ...privilege,
          privilege: privileges.find(
            (obj) => obj.displayValue === ValidateConstant.DELETE
          ),
        };
      });
      setUserRolePrivileges(updatedPrivileges);
    } else {
      const updatedPrivileges = userRolePrivileges.map((privilege) => {
        return {
          ...privilege,
          privilege: privileges.find(
            (obj) => obj.displayValue === ValidateConstant.NONE
          ),
        };
      });
      setUserRolePrivileges(updatedPrivileges);
    }
  };

  const handlePrivilege = (
    userRolePrivilegesItem: any,
    currentPrivilege: any
  ) => {
    const updatedPrivileges = userRolePrivileges.map((privilege) => {
      if (privilege.guid === userRolePrivilegesItem.guid) {
        return {
          ...privilege,
          privilege: currentPrivilege,
        };
      }
      return privilege;
    });
    setUserRolePrivileges(updatedPrivileges);
  };
  // nmsdms,md,
  const Schema = object().shape({
    roleName: validationRules.roleNameValidationSchema,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRoleName(value);
    Schema.validateAt(name, { roleName: value })
      .then(() => {
        setRoleNameErrors("");
      })
      .catch((error) => {
        setRoleNameErrors(error.message);
      });
  };
  const handleSubmitForm = async () => {
    const updatedUserRoles = userRolePrivileges.map((item) => {
      return {
        objectOrActionGuid: item.objectOrAction.guid,
        privilegeGuid: item.privilege.guid,
      };
    });
    await updateRolePrivilege(updatedUserRoles, currentUpdatingData.guid)
      .then((response: any) => {
        if (response.data.status === ERROR_STATUS_KEY) {
          setRequestErrors(response.data.statusDesc);
        }
      })
      .catch((error: any) => console.error(error));
    if (currentUpdatingData.displayValue !== roleName) {
      await updateRoleName(roleName, currentUpdatingData.guid)
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            setRequestErrors(response.data.statusDesc);
          } else {
            setPageRenderSate(ValidateConstant.INDEX);
          }
        })
        .catch((error: any) => console.error(error));
    } else {
      setPageRenderSate(ValidateConstant.INDEX);
    }
  };
  const handleCheckboxChange = (
    e: any,
    item: any,
    privilegeType: string,
    defaultPrivilegeType: string
  ) => {
    const isChecked = e.target.checked;
    const privilegeToHandle = isChecked
      ? privileges.find((obj) => obj.displayValue === privilegeType)
      : privileges.find((obj) => obj.displayValue === defaultPrivilegeType);

    handlePrivilege(item, privilegeToHandle);
  };

  const readChecker = (e: any, item: any) => {
    handleCheckboxChange(e, item, ValidateConstant.READ, ValidateConstant.NONE);
  };

  const modifyChecker = (e: any, item: any) => {
    handleCheckboxChange(
      e,
      item,
      ValidateConstant.MODIFY,
      ValidateConstant.READ
    );
  };

  const deleteChecker = (e: any, item: any) => {
    handleCheckboxChange(
      e,
      item,
      ValidateConstant.DELETE,
      ValidateConstant.CREATE
    );
  };

  const createChecker = (e: any, item: any) => {
    handleCheckboxChange(
      e,
      item,
      ValidateConstant.CREATE,
      ValidateConstant.MODIFY
    );
  };

  return (
    <form>
      <CardHeader>
        <CardHeaderCancel
          title={t("UPDATE.ROLE")}
          setPageRenderSateFun={() => setPageRenderSate(ValidateConstant.INDEX)}
        />
      </CardHeader>
      <br />
      {requestErrors.length > 0 && <ErrorMessageBox massage={requestErrors} />}
      <div className="row my-4  fs-6  ">
        <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex align-items-center">
          {t("ROLE.NAME")}
        </div>
        <div className="col-6">
          <InputTextField
            placeholder={t("ROLE.NAME")}
            id="roleName"
            name="roleName"
            type="text"
            value={roleName}
            onChange={(e) => {
              handleChange(e);
            }}
            error={Boolean(roleNameErrors)}
            helperText={roleNameErrors}
          />
        </div>
      </div>
      {userRolePrivileges ? (
        <>
          <div className="text-muted d-flex mb-10 ">{t("PRIVILEGE")}</div>
          <div className="row my-4  fs-6  ">
            <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex   align-items-center">
              {t("Administrator Access")}
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-5">
                  <label className="form-check form-check-sm form-check-custom form-check-solid me-9">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue=""
                      id="kt_roles_select_all"
                      checked={
                        userRolePrivileges
                          ? userRolePrivileges.every(
                              (item) =>
                                item.privilege.displayValue ===
                                ValidateConstant.DELETE
                            )
                          : false
                      }
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        selectAllPrivilegeHandler(isChecked);
                      }}
                    />
                    <span className="form-check-label">{t("SELECT.ALL")}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          {userRolePrivileges.map((item: any) => {
            return (
              <div key={item.guid} className="row my-4  fs-6  ">
                <div className="col-3 mb-4 fs-6 text-gray-800 fw-semibold d-flex   align-items-center">
                  {item.objectOrAction.displayValue}
                </div>
                <div className="col-9">
                  <div className="d-flex">
                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid
                            ? findNonePrivilege(item.guid, userRolePrivileges)
                            : false
                        }
                        onChange={() => {
                          handlePrivilege(
                            item,
                            privileges.find(
                              (obj) =>
                                obj.displayValue === ValidateConstant.NONE
                            )
                          );
                        }}
                      />
                      <span className="form-check-label">{t("NONE")}</span>
                    </label>

                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid
                            ? findReadPrivilege(item.guid, userRolePrivileges)
                            : false
                        }
                        onChange={(e) => {
                          readChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("READ")}</span>
                    </label>
                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid
                            ? findModifyPrivilege(item.guid, userRolePrivileges)
                            : false
                        }
                        onChange={(e) => {
                          modifyChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("MODIFY")}</span>
                    </label>

                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid
                            ? findCreatePrivilege(item.guid, userRolePrivileges)
                            : false
                        }
                        onChange={(e) => {
                          createChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("CREATE")}</span>
                    </label>

                    <label className="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={item.objectOrAction.displayValue}
                        checked={
                          item.guid
                            ? findDeletePrivilege(item.guid, userRolePrivileges)
                            : false
                        }
                        onChange={(e) => {
                          deleteChecker(e, item);
                        }}
                      />
                      <span className="form-check-label">{t("DELETE")}</span>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="fs-6  text-muted">
          {t("messages:NO.PRIVILEGE.FOUND")}
        </div>
      )}
      <div className="row justify-content-start ">
        <div className="col-4">
          <div className="d-grid my-4">
            <Button
              classes="btn-sm rounded-pill btn-primary"
              onClick={(e) => {
                e.preventDefault();
                handleSubmitForm();
              }}
              disabled={Boolean(roleNameErrors)}
            >
              <span className=" text-uppercase"> {t("UPDATE")}</span>
            </Button>
          </div>
        </div>
      </div>
      <CardBottomSubmitBottom
        label={t("UPDATE")}
        isError={Boolean(roleNameErrors)}
        handleSubmit={() => handleSubmitForm()}
      />
    </form>
  );
};

export default UpdateRole;
