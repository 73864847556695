import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/SearchableDropdown.css";
import { useTranslation } from "react-i18next";
interface ProductDropdownProps {
  label?: string;
  name: string;
  value: string;
  isDisable?: boolean;
  onChange: (event: React.ChangeEvent<any>) => void;
  getSelectedOption?: (guid: string) => void;
  selectionOptions: { label: string; value: string }[];
  error?: boolean;
  helperText?: string | boolean;
}

const ProductDropdown: React.FC<ProductDropdownProps> = ({
  label,
  name,
  value,
  onChange,
  isDisable = false,
  getSelectedOption,
  selectionOptions,
  error,
  helperText,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState("");

  const filteredselectionOptions = selectionOptions.filter((option) => {
    return option.label.toLowerCase().startsWith(searchTerm.toLowerCase());
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setDropdownVisible(true);
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setDropdownVisible(false);
      setSearchTerm("");
    }
  };

  interface OptionType {
    label: string;
    value: string;
  }
  const handleOptionClick = (option: OptionType) => {
    if (getSelectedOption) {
      getSelectedOption(option.value);
    }
    onChange({
      target: {
        name,
        value: option.value,
      },
    } as React.ChangeEvent<any>);
    setDropdownVisible(false);
    setSearchTerm("");
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    try {
      if (value) {
        let selectedData = filteredselectionOptions.filter((item) => {
          return item.value === value;
        });
        if (selectedData) {
          setSelectedLabel(selectedData[0]?.label);
        }
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, [value]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={dropdownRef}>
      {label && (
        <label className="form-label text-dark fs-6 mb-0 ">{label}</label>
      )}
      <div className="dropdown-container  w-100">
        <div
          onKeyDown={() => {
            setDropdownVisible(!dropdownVisible);
          }}
          onClick={() => {
            setDropdownVisible(!dropdownVisible);
          }}
          className={`w-100 border text-primary-white border-primary-gray  py-2 fw-normal ${
            error ? "is-invalid" : ""
          } ${isDisable ? "border-0 form-control" : "border-1 form-select"}`}
        >
          {value ? selectedLabel : t("SELECT.OPTIONS")}
        </div>
        {dropdownVisible && (
          <div className="option-box bg-light " style={{ padding: "4px" }}>
            <div className="w-100 p-2">
              <input
                type="text"
                className="w-100 form-control p-2"
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
            <ul className="option-list scrollbar-danger">
              {filteredselectionOptions.map((option) => (
                <li
                  key={option.value}
                  onKeyDown={() => handleOptionClick(option)}
                  onClick={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))}
              {filteredselectionOptions.length <= 0 && (
                <li>
                  <li className="mt-2">{t("NO.DATA.FOUND")}</li>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDropdown;
