// Base path constants
export const VERSION: string = "/v1";
export const AUTH: string = "/auth";
export const SUBSCRIPTION: string = "/subscription";

// Auth API endpoints
export const authApiEndpoint = {
  ENDPOINT_LOGIN: AUTH + "/login",
  ENDPOINT_SIGNUP: AUTH + "/signup",
  ENDPOINT_RESET_PASSWORD_TOKEN: AUTH + "/reset_password",
};

// Subscription API endpoints
export const subscriptionApiEndpoint = {
  ENDPOINT_COMPANY_DOMAIN: SUBSCRIPTION + VERSION + "/company/domain",
  ENDPOINT_RESEND_EMAIL: SUBSCRIPTION + VERSION + "/user/activation/resend",
  ENDPOINT_USER_ACTIVE: VERSION + "/user/activate",
  ENDPOINT_VALIDATE_ACTIVATION: SUBSCRIPTION + VERSION + "/user/activation",
  ENDPOINT_COMPANY_DETAILS: SUBSCRIPTION + VERSION + "/company/me",
  ENDPOINT_CREATE_COMPANY: SUBSCRIPTION + VERSION + "/company",
  ENDPOINT_PRODUCTS: SUBSCRIPTION + VERSION + "/user/products",
  ENDPOINT_ALL_PRODUCTS: SUBSCRIPTION + VERSION + "/product_type",
  ENDPOINT_SERVICES: SUBSCRIPTION + VERSION + "/services",
  ENDPOINT_OFFERED_SERVICES:
    SUBSCRIPTION + VERSION + "/company/offered_services",
  ENDPOINT_LEGAL_DETAILS_FIELD:
    SUBSCRIPTION + VERSION + "/legal_details/country",
  ENDPOINT_COMPANY_LEGAL_DETAILS:
    SUBSCRIPTION + VERSION + "/legal_details/company",
  ENDPOINT_COMPANY_PREFERENCES:
    SUBSCRIPTION + VERSION + "/company/preference/me",
  ENDPOINT_COMPANY_PREFERENCES_SYSTEM_MODE:
    SUBSCRIPTION + VERSION + "/company/preference/system_mode/me",
  ENDPOINT_COMPANY_BRANCH: SUBSCRIPTION + VERSION + "/company/branch",
  ENDPOINT_USER: SUBSCRIPTION + VERSION + "/user/me",
  ENDPOINT_ALL_USER: SUBSCRIPTION + VERSION + "/user",
  ENDPOINT_USER_ROLE: SUBSCRIPTION + VERSION + "/user_role",
  ENDPOINT_USER_PREFERENCES: SUBSCRIPTION + VERSION + "/user/preference/me",
  ENDPOINT_USER_PREFERENCES_SYSTEM_MODE:
    SUBSCRIPTION + VERSION + "/user/preference/system_mode/me",
  RESEND_USER_INVITATION: SUBSCRIPTION + VERSION + "/user",
  ENDPOINT_ASSIGNED_SPECIFIC_ROLE: SUBSCRIPTION + VERSION + "/user_role",
  ENDPOINT_REBRANDING: SUBSCRIPTION + VERSION + "/rebranding/me",
  ENDPOINT_CREATE_REBRANDING: SUBSCRIPTION + VERSION + "/rebranding",
  ENDPOINT_ROLE_PRIVILEGE: SUBSCRIPTION + VERSION + "/user_role/privilege",
  ENDPOINT_UPDATE_ROLE_PRIVILEGE: SUBSCRIPTION + VERSION + "/user_role",
};

// common API endpoints
export const commonApiEndpoint = {
  ENDPOINT_COUNTRY: VERSION + "/country",
  ENDPOINT_STATE: VERSION + "/state",
  ENDPOINT_CITY: VERSION + "/city",
};
