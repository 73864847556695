const Pagination = () => {
  return (
    <div className="row justify-content-end">
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            <li className="paginate_button page-item previous disabled">
              <span className="page-link">
                <i className="previous" />
              </span>
            </li>
            <li className="paginate_button page-item active rounded-pill">
              <span className="page-link pointer rounded-pill">1</span>
            </li>
            <li className="paginate_button page-item ">
              <span className="page-link pointer rounded-pill">2</span>
            </li>
            <li className="paginate_button page-item next">
              <span className="page-link">
                <i className="next" />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
