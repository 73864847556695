import { AxiosResponse } from "axios";
import apiInterceptor from "./apiInterceptor";
import {
  CompanyDetails,
  ResetPassword,
  Signup,
  ComapnyDomain,
  SelectionOptionResponse,
} from "helpers/models/auth_models";
import {
  authApiEndpoint,
  commonApiEndpoint,
  subscriptionApiEndpoint,
} from "constant/apiConstants";

export async function getCountries(): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${commonApiEndpoint.ENDPOINT_COUNTRY}?limit=300`
    );
  return response;
}

export async function getStates(
  guid: string | undefined
): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${commonApiEndpoint.ENDPOINT_STATE}?limit=250&country_guid=${guid}`
    );
  return response;
}

export async function getCity(
  guid: string | undefined
): Promise<AxiosResponse> {
  const response: AxiosResponse<SelectionOptionResponse> =
    await apiInterceptor.get<SelectionOptionResponse>(
      `${commonApiEndpoint.ENDPOINT_CITY}?limit=3000&state_guid=${guid}`
    );
  return response;
}

export async function getCompanyDomain(): Promise<AxiosResponse> {
  const response: AxiosResponse<ComapnyDomain[]> = await apiInterceptor.get<
    ComapnyDomain[]
  >(
    `${subscriptionApiEndpoint.ENDPOINT_COMPANY_DOMAIN}?order=ASC&sortBy=ObjId&limit=30`
  );

  return response;
}

export function login(email: string, password: string) {
  return apiInterceptor.post<any>(`${authApiEndpoint.ENDPOINT_LOGIN}`, {
    email,
    password,
  });
}

export function signup(signup: Signup) {
  return apiInterceptor.post<any>(`${authApiEndpoint.ENDPOINT_SIGNUP}`, signup);
}

export function resendActivationLinkWithEmail(email: string | undefined) {
  return apiInterceptor.get<any>(
    `${subscriptionApiEndpoint.ENDPOINT_RESEND_EMAIL}?user_email=${email}`
  );
}

export function resendActivationLinkWithToken(
  token: string | undefined | null
) {
  return apiInterceptor.get<any>(
    `${subscriptionApiEndpoint.ENDPOINT_RESEND_EMAIL}?verification_token=${token}`
  );
}

export function userActivation(token: string | null) {
  return apiInterceptor.get<any>(
    `${subscriptionApiEndpoint.ENDPOINT_USER_ACTIVE}?token=${token}`
  );
}
export function validateActivation(userEmail: string) {
  return apiInterceptor.post(
    `${subscriptionApiEndpoint.ENDPOINT_VALIDATE_ACTIVATION}`,
    {
      userEmail: userEmail,
    }
  );
}

export function generateResetPasswordToken(userEmail: {
  userEmail: string | undefined;
}) {
  return apiInterceptor.post(
    `${authApiEndpoint.ENDPOINT_RESET_PASSWORD_TOKEN}`,
    userEmail
  );
}

export function validateForgotPasswordToken(token: string | null) {
  return apiInterceptor.get(
    `${authApiEndpoint.ENDPOINT_RESET_PASSWORD_TOKEN}/${token}/validate`
  );
}

export function resetPassword(data: ResetPassword) {
  return apiInterceptor.put(
    `${authApiEndpoint.ENDPOINT_RESET_PASSWORD_TOKEN}/${data.token}`,
    {
      password: data.password,
    }
  );
}

export function getCompanyDetails() {
  return apiInterceptor.get(
    `${subscriptionApiEndpoint.ENDPOINT_COMPANY_DETAILS}`
  );
}
export function createCompany(companyDetails: CompanyDetails) {
  return apiInterceptor.post(
    `${subscriptionApiEndpoint.ENDPOINT_CREATE_COMPANY}`,
    companyDetails
  );
}

export function updateCompany(companyDetails: CompanyDetails) {
  return apiInterceptor.put(
    `${subscriptionApiEndpoint.ENDPOINT_CREATE_COMPANY}`,
    companyDetails
  );
}
