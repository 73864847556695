import React, { FC } from "react";
import { useTranslation } from "react-i18next";
interface FormButtonProps {
  isBtnDisabled?: boolean;
  loading: boolean;
  formik?: any;
  btnLabel: string;
  classes?: string;
}
const FormButton: FC<FormButtonProps> = ({
  btnLabel,
  formik,
  isBtnDisabled = false,
  loading,
  classes = "",
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-grid my-4">
      <button
        type="submit"
        className={classes + " btn btn-primary bg-primary-text"}
        disabled={
          formik ? formik.isSubmitting || !formik.isValid : isBtnDisabled
        }
      >
        {!loading && (
          <span className="indicator-label text-uppercase bg-primary-text ">
            {btnLabel}
          </span>
        )}
        {loading && (
          <span
            className="indicator-progress bg-primary-text"
            style={{ display: "block" }}
          >
            {t("WAIT")}...{" "}
            <span className="spinner-border spinner-border-sm align-middle ms-2 bg-primary-text"></span>
          </span>
        )}
      </button>
    </div>
  );
};

export default FormButton;
