import { KTIcon } from "helpers";
import { useCommonContext } from "hooks‬/CommonContext";

import React from "react";
import Toast from "react-bootstrap/Toast";

const ErrorToast = () => {
  const { showError, errorMessage, hideErrorToast } = useCommonContext();

  return (
    <Toast
      onClose={hideErrorToast}
      show={showError}
      delay={3000}
      autohide
      style={{
        position: "fixed",
        top: "75px",
        right: "25px",
        backgroundColor: "#E6FFFA",
        borderLeft: "5px solid #f1416c",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1050,
        maxWidth: "280px",
      }}
    >
      <Toast.Header
        className="py-4"
        closeButton={false}
        style={{ borderBottom: "none" }}
      >
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <KTIcon className=" fs-1 text-danger" iconName="information-2" />
            <strong
              className="me-auto fs-6 px-2"
              style={{ color: "#333", fontWeight: "500" }}
            >
              {errorMessage}
            </strong>
          </div>
          <button
            type="button"
            className=" fs-14 p-0"
            onClick={hideErrorToast}
            aria-label="Close"
            style={{
              marginLeft: "auto",
              background: "transparent",
              border: "none",
            }}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </button>
        </div>
      </Toast.Header>
    </Toast>
  );
};

export default ErrorToast;
