import Button from "components/base/button/Button";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import FormButton from "./FormButton";

const CardHeaderEditSave = ({
  title,
  isDisabled,
  setIsDisabled,
  formik,
  loading,
  cancelToUpdate,
}: {
  title: string;
  isDisabled: boolean;
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
  formik: any;
  loading: boolean;
  cancelToUpdate: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="m-0">{title}</h3>
      <div className="m-0">
        {isDisabled ? (
          <div className="my-4">
            <Button
              classes="btn-sm rounded-pill"
              onClick={() => setIsDisabled(false)}
            >
              <span className=" text-uppercase"> {t("EDIT")}</span>
            </Button>
          </div>
        ) : (
          <div className="d-flex gap-2">
            <FormButton
              btnLabel={t("SAVE")}
              classes="btn-sm rounded-pill my-0"
              formik={formik}
              isBtnDisabled={formik.isSubmitting || !formik.isValid}
              loading={loading}
            />{" "}
            <div className="  my-4">
              <Button
                classes="btn-sm rounded-pill btn-danger"
                type="button"
                onClick={() => {
                  cancelToUpdate();
                }}
              >
                <span className=" text-uppercase"> {t("CANCEL")}</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CardHeaderEditSave;
