import React, { Dispatch, FC, SetStateAction } from "react";
import { updateColors } from "./ThemeHelpers";
interface PredefinedThemesProps {
  baseColor: string;
  activeColor: string;
  lightColor: string;
  currentBaseColor: string | undefined;
  setCurrentBaseColor: Dispatch<SetStateAction<string | undefined>>;
}
const PredefinedThemes: FC<PredefinedThemesProps> = ({
  baseColor,
  activeColor,
  lightColor,
  currentBaseColor,
  setCurrentBaseColor,
}) => {
  return (
    <div>
      <div className="bg-light  p-2 radius-12 position-relative">
        {baseColor === currentBaseColor && (
          <div
            className="position-absolute"
            style={{ zIndex: "99", right: "4px", top: "2px" }}
          >
            <i className="fas fa-check-circle  text-dark"></i>
          </div>
        )}
        <div
          className="w-40px h-40px  rounded-circle overflow-hidden d-flex pointer"
          onClick={() => {
            updateColors(baseColor);
            setCurrentBaseColor(baseColor);
          }}
          onKeyDown={() => {
            updateColors(baseColor);
            setCurrentBaseColor(baseColor);
          }}
          style={{
            transform: "rotate(45deg)",
            border: "1px solid " + baseColor,
          }}
        >
          <div
            className="w-20px h-40px"
            style={{ background: baseColor }}
          ></div>
          <div
            className="w-20px h-40px"
            style={{ background: lightColor }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default PredefinedThemes;
