import { Dispatch, FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { KTIcon } from "../../helpers";

interface PropContent {
  planType: string;
  disc: string;
  price: string;
  duration: string;
  isActive: boolean;
  selectedPlan: string;
  setSelectedPlans: Dispatch<SetStateAction<string>>;
}

const PlanCard: FC<PropContent> = ({
  planType,
  disc,
  price,
  duration,
  isActive,
  selectedPlan,
  setSelectedPlans,
}) => {
  const navigate = useNavigate();
  return (
    <div className="col-md-4 mb-5 ">
      <div
        onKeyDown={() => {
          setSelectedPlans(planType);
        }}
        onClick={() => {
          setSelectedPlans(planType);
        }}
        className={`card card-body p-10
         ${
           selectedPlan === planType
             ? "border border-primary"
             : "hover-selected-plan"
         } `}
      >
        <div className="text-center mb-5" style={{ padding: "0 50px" }}>
          <div>
            <h5 className="card-title mb-4" style={{ fontSize: "1.5rem" }}>
              {planType}
            </h5>
          </div>
          <div>
            <div className="mb-4 text-muted" style={{ fontSize: "0.8rem" }}>
              {disc}
            </div>
          </div>
          <h6 className="card-subtitle mb-4">
            <span className="text-primary">
              ₹<span style={{ fontSize: "1.5rem" }}>{price}</span>
            </span>
            /{duration}
          </h6>
        </div>
        <div>
          <div className="d-flex justify-content-between mb-3">
            <div>Up to 10 Active Users</div>

            <div id="checkIcon">
              <KTIcon iconName="check-circle" className="fs-2" />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div>Up to 30 Active Users</div>
            <div id="checkIcon">
              <KTIcon iconName="check-circle" className="fs-2" />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div>Up to 10 Active Users</div>
            <div id="checkIcon">
              <KTIcon iconName="check-circle" className="fs-2" />
            </div>
          </div>

          <div className="d-flex justify-content-between mb-3">
            <div>Up to 10 Active Users</div>
            <div id="checkIcon">
              <KTIcon iconName="check-circle" className="fs-2" />
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3 text-muted">
            <div>Up to 10 Active Users</div>
            <div>
              <KTIcon iconName="cross-circle" className="fs-2" />
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3 text-muted">
            <div>Up to 10 Active Users</div>
            <div>
              <KTIcon iconName="cross-circle" className="fs-2" />
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3 text-muted">
            <div>Up to 10 Active Users</div>
            <div>
              <KTIcon iconName="cross-circle" className="fs-2" />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <div
            className="btn btn-primary btn-sm fw-bold"
            style={{
              marginRight: "10px",
              minWidth: "82px",
            }}
            onKeyDown={() => navigate("/user-management/subscriptions/add")}
            onClick={() => navigate("/user-management/subscriptions/add")}
          >
            <span style={{ color: "#fff" }}>
              {selectedPlan === planType ? (
                <span>Selected</span>
              ) : (
                <span>Select</span>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
