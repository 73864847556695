import { useState } from "react";
import PlanCard from "./PlanCard";
import "./style.css";
import { useNavigate } from "react-router-dom";

const PricingPage = () => {
  const [planDuration, setPlanDuration] = useState<string>("Monthly");
  const [selectedPlan, setSelectedPlan] = useState<string>("Free");
  const navigate = useNavigate();
  const selectPlanDuration = (duration: string) => {
    setPlanDuration(duration);
  };
  return (
    <>
      <div>
        <div className=" d-flex flex-column align-items-center">
          <h1>Choose Your Plan</h1>
          <div>
            If you need more info about our pricing, please check{" "}
            <span className="text-primary fw-bold">Pricing Guidelines.</span>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <div
              className="border-wrapper p-1"
              style={{
                border: "1px solid #eeeeee",
                display: "inline-block",
                borderRadius: "8px",
              }}
            >
              <div
                onKeyDown={() => {
                  selectPlanDuration("Monthly");
                }}
                onClick={() => {
                  selectPlanDuration("Monthly");
                }}
                className={`btn btn-sm fw-bold
                  ${
                    planDuration === "Monthly"
                      ? "toggle-button-active btn-primary"
                      : "toggle-button-inactive "
                  }
                `}
                style={{ marginRight: "10px", minWidth: "82px" }}
              >
                <span>Monthly</span>
              </div>

              <div
                onKeyDown={() => {
                  selectPlanDuration("Annual");
                }}
                onClick={() => {
                  selectPlanDuration("Annual");
                }}
                className={`btn btn-sm fw-bold
                ${
                  planDuration === "Annual"
                    ? "toggle-button-active btn-primary"
                    : "toggle-button-inactive "
                }
              `}
                style={{ minWidth: "82px" }}
              >
                <span>Annual</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <PlanCard
            planType="Free"
            disc="Hello buy this plan to use our product"
            price="0"
            duration="Mon"
            isActive={true}
            selectedPlan={selectedPlan}
            setSelectedPlans={setSelectedPlan}
          />
          <PlanCard
            planType="Trial"
            disc="Hello buy this plan to use our product"
            price="339"
            duration="Mon"
            isActive={true}
            selectedPlan={selectedPlan}
            setSelectedPlans={setSelectedPlan}
          />
          <PlanCard
            planType="Professional"
            disc="Hello buy this plan to use our product"
            price="999"
            duration="Mon"
            isActive={true}
            selectedPlan={selectedPlan}
            setSelectedPlans={setSelectedPlan}
          />
          <PlanCard
            planType="Business"
            disc="Hello buy this plan to use our product"
            price="1500"
            duration="Mon"
            isActive={true}
            selectedPlan={selectedPlan}
            setSelectedPlans={setSelectedPlan}
          />
          <PlanCard
            planType="Enterprice"
            disc="Hello buy this plan to use our product"
            price="2000"
            duration="Mon"
            isActive={true}
            selectedPlan={selectedPlan}
            setSelectedPlans={setSelectedPlan}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary "
            onKeyDown={() => {
              navigate("/pricing-comparison");
            }}
            onClick={() => {
              navigate("/pricing-comparison");
            }}
          >
            See our complete feature comparison
          </button>
        </div>
      </div>
    </>
  );
};

export default PricingPage;
