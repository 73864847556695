import React, { FC } from "react";
interface Props {
  title: string;
  onClickAction: () => void;
}
const ActionMenuItem: FC<Props> = ({ title, onClickAction }) => {
  return (
    <div className="menu-item px-3">
      <span className="menu-link px-3 pointer" onKeyDown={() => onClickAction()} onClick={() => onClickAction()}>
        {title}
      </span>
    </div>
  );
};

export default ActionMenuItem;
