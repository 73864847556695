import { WithChildren } from "helpers";
import React from "react";

const Table = ({ children }: WithChildren) => {
  return (
    <div className="table-responsive">
      <table className="table align-middle table-row-bordered fs-6 gy-5 dataTable no-footer">
        {children}
      </table>
    </div>
  );
};

export default Table;
