import Button from "components/base/button/Button";
import React from "react";
import { useTranslation } from "react-i18next";

const CardHeaderCancel = ({
  title,
  setPageRenderSateFun,
}: {
  title: string;
  setPageRenderSateFun: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <h3 className="m-0">{title}</h3>
      <div className="  my-4">
        <Button
          classes="btn-sm rounded-pill btn-danger"
          onClick={(e) => {
            e.preventDefault();
            setPageRenderSateFun();
          }}
        >
          <span className=" text-uppercase"> {t("CANCEL")}</span>
        </Button>
      </div>
    </>
  );
};

export default CardHeaderCancel;
