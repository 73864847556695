import { FC, useEffect, useState } from "react";
import ProductCard from "components/cards/ProductCard";

import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import { useTranslation } from "react-i18next";
import {
  getAllProducts,
  getProducts,
} from "services/companyManagementRequests";
const Dashboard: FC = () => {
  const { t } = useTranslation();

  const [purchasedProduct, setPurchasedProduct] = useState<any[]>([]);
  // const [otherProduct, setOtherProduct] = useState<any[]>([]);
  const [allProduct, setAllProduct] = useState<any[]>([]);
  useEffect(() => {
    const handleProducts = async () => {
      try {
        await getProducts()
          .then((response: any) => {
            console.log(response);
            if (response.data.status === "ERROR") {
              console.log(response.data.statusDesc);
            } else {
              setPurchasedProduct(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    };
    const handleAllProducts = async () => {
      try {
        await getAllProducts()
          .then((response: any) => {
            if (response.data.status === "ERROR") {
              console.log(response.data.statusDesc);
            } else {
              setAllProduct(response.data.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    };
    handleAllProducts();
    handleProducts();
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (purchasedProduct.length > 0) {
  //     const purchasedGuids = purchasedProduct.map(
  //       (item) => item.productTypeDTO.guid
  //     );

  //     const notPurchasedApps = allProduct.filter(
  //       (app) => !purchasedGuids.includes(app.guid)
  //     );
  //     setOtherProduct(notPurchasedApps);
  //   } else {
  //     setOtherProduct(allProduct);
  //   }
  // }, [allProduct, purchasedProduct]);

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective label={t("DASHBOARD")} />
      </Breadcrumbs>
      {/* Here we have not yet decided which details to show for that the details are currently hard-coded*/}
      <div className="mb-5">
        <h1>Hey Niyantras, Welcome Back!</h1>
      </div>
      <div className="row ">
        <div className="col-8  px-5 mb-5  ">
          <div
            className="radius-24   bg-primary row p-5"
            style={{ height: "170px" }}
          >
            <div className="col-3 text-white border-end  d-flex justify-content-center flex-column p-4 my-4 align-items-center ">
              <div className="bg-primary-text" style={{ fontSize: "22px" }}>
                {t("TOTAL.APPS")}
              </div>
              <div
                className="bg-primary-text fw-bolder lh-1"
                style={{ fontSize: "54px" }}
              >
                {allProduct.length ? allProduct.length : 0}
              </div>
            </div>
            <div className="col-3 bg-primary-text border-end flex-1 d-flex justify-content-center flex-column my-4 p-4 align-items-center">
              <div className="bg-primary-text" style={{ fontSize: "22px" }}>
                {t("MY.APPS")}
              </div>
              <div
                className="bg-primary-text fw-bolder lh-1"
                style={{ fontSize: "54px" }}
              >
                {purchasedProduct.length ? purchasedProduct.length : 0}
              </div>
            </div>
            <div className="col-6 bg-primary-text d-flex justify-content-center flex-column p-4 align-items-center bg-primary-text ">
              <div>
                <span className="fw-bold">Utilized Time</span>
                <ul>
                  <li>How Niyantras One is helping you or saving your time</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4   mb-5 px-5  ">
          <div
            className="radius-24 mb-5 bg-primary bg-primary-text row  text-center align-content-center"
            style={{ height: "170px", fontSize: "20px" }}
          >
            <span>Usage here</span>
            <span>or</span>
            <span>Important Details here</span>
          </div>
        </div>
      </div>

      <h1 className="my-5">{t("MY.APPS")}</h1>
      <div className="row ">
        <ProductCard productTitle="ATS" />
      </div>

      {/* {purchasedProduct.length > 0 && (
        <>
          <h1 className="my-5">{t("MY.APPS")}</h1>{" "}
          <div className="row ">
            {purchasedProduct.map((item: any) => {
              return (
                <ProductCard
                  key={item.productTypeDTO.name}
                  productTitle={item.productTypeDTO.name}
                />
              );
            })}{" "}
          </div>
        </>
      )}
      <h1 className="mt-5">{t("OTHER.APPS")}</h1>{" "}
      <div className="row ">
        {otherProduct.map((item: any) => {
          return (
            <ProductCard
              key={item.guid}
              productTitle={item.name}
              isBought={false}
            />
          );
        })}{" "}
      </div> */}
    </div>
  );
};

export { Dashboard };
