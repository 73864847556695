export const initPreferenceValue = {
  companyPreferenceGuid: "",
  dateFormat: "",
  displayLanguage: "",
  displayTimezone: "",
  productTypeGuid: "",
  productTypeName: "",
  systemMode: "",
  timeFormat: "",
};
