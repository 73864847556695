import { FC, useEffect } from "react";
import roleIcon from "assets/svg/role-icon.svg";
import ActionMenuBox from "components/menu/action-menu/ActionMenuBox";
import ActionMenuItem from "components/menu/action-menu/ActionMenuItem";
import { MenuComponent } from "assets/ts/components";
import { useTranslation } from "react-i18next";
import Button from "components/base/button/Button";
import DeleteActionMenuItem from "components/menu/action-menu/DeleteActionMenuItem";
interface TypesOfProps {
  roleTitle: string;
  numberOfAssigned: string;
  editHandler: () => void;
  viewRoleHandler: () => void;
  deleteHandler: () => void;
}

const MenuTrigger = ({
  editHandler,
  deleteHandler,
}: {
  editHandler: () => void;
  deleteHandler: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <ActionMenuBox>
      <ActionMenuItem
        title={t("EDIT")}
        onClickAction={() => {
          editHandler();
        }}
      />
      <DeleteActionMenuItem
        title={t("DELETE")}
        onClickAction={() => {
          deleteHandler();
        }}
      />
    </ActionMenuBox>
  );
};

const RoleCard: FC<TypesOfProps> = ({
  roleTitle,
  editHandler,
  numberOfAssigned,
  viewRoleHandler,
  deleteHandler,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
      <div className="card border  p-10">
        <div className="border-bottom border-muted d-flex flex-column align-items-center mb-2">
          <h3>{roleTitle}</h3>{" "}
        </div>
        <div className="m-auto">
          <img
            className="w-150px h-150px"
            src={roleIcon}
            alt="email-verified"
          />
        </div>
        <div className="text-center">
          {t("messages:USER.WITH.ROLE")} {numberOfAssigned}
        </div>
        <div className="d-flex flex-md-row flex-column  align-items-center justify-content-center pt-5">
          <Button
            className="btn btn-primary btn-sm m-1 "
            onClick={() => {
              viewRoleHandler();
            }}
          >
            <span className="indicator-label text-capitalize">
              {t("VIEW.ROLE")}
            </span>
          </Button>
          <div>
            <div
              className="btn btn-secondary btn-active-light btn-sm m-1"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              {t("MORE.ACTIONS")}
            </div>
            <MenuTrigger
              deleteHandler={deleteHandler}
              editHandler={editHandler}
            />
          </div>
        </div>
      </div>
  );
};
export default RoleCard;
