import Pagination from "components/base/Pagination";
import SearchBox from "components/base/SearchBox";
import CardBody from "components/cards/CardBody";
import Table from "components/table/Table";
import TheadRow from "components/table/TheadRow";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import Breadcrumbs from "components/base/breadcrumbs";
const MenuTrigger = () => {
  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <span className="menu-link px-3 pointer">View</span>
      </div>

      <div className="menu-item px-3">
        <span className="menu-link px-3 pointer">Edit</span>
      </div>

      <div className="menu-item px-3">
        <span
          data-kt-subscriptions-table-filter="delete_row"
          className="menu-link px-3"
        >
          Delete
        </span>
      </div>
    </div>
  );
};

const Index = () => {
  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label="Dashboard" />
        <BreadcrumbDirective label="Product & Subscription" />
      </Breadcrumbs>
      <div className="card p-10  my-5">
        <div className="d-flex justify-content-between align-items-center border-bottom border-primary mb-5">
          <h3 className="my-4">Product & Subscription</h3>
        </div>{" "}
        <div className="row justify-content-between">
          <div className="col-3">
            <SearchBox />
          </div>
          <div className="col-5">
            <div className="row align-items-center justify-content-end">
              <div className="col-2 text-end">
                <i className="ki-outline ki-filter text-primary fs-1 pointer">
                  <span className="path2" />
                </i>
              </div>

              <div className="col-2 text-end">
                <i className="ki-outline ki-exit-up text-primary fs-1 pointer">
                  <span className="path2" />
                </i>
              </div>
            </div>
          </div>
        </div>
        <CardBody>
          <Table>
            <TheadRow>
              <th className="w-10px pe-2 sorting_disabled">
                <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    data-kt-check="true"
                    data-kt-check-target="#subscriptions_table .form-check-input"
                    defaultValue={1}
                  />
                </div>
              </th>
              <th className="min-w-125px sorting">PRODUCT NAME</th>
              <th className="min-w-125px sorting">DESCRIPTION</th>
              <th className="min-w-125px sorting">Subscription STATUS</th>
              <th className="min-w-125px sorting"> RENEWAL DATE</th>
              <th className="min-w-125px sorting">Actions</th>
            </TheadRow>
            <tbody className="fw-semibold">
              <tr>
                <td>
                  <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue={1}
                    />
                  </div>
                </td>
                <td>ATS</td>
                <td>Description here</td>
                <td>
                  <div className="badge badge-light-warning">pendding</div>
                </td>
                <td data-order="2023-11-15T00:00:00+05:30">Apr 15, 2023</td>
                <td>
                  <a
                    href="https://niyantrasone.com/"
                    className="btn btn-secondary btn-active-light btn-sm rounded-pill"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                  >
                    Actions <i className="ki-duotone ki-down fs-5" />
                  </a>
                  <MenuTrigger />
                </td>
              </tr>
            </tbody>
          </Table>

          <Pagination />
        </CardBody>
      </div>
      {/* <ExportModal modalId="n_one_subscriptions_export_modal" /> */}
    </div>
  );
};
export default Index;
