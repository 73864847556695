import React, { FC } from "react";
import { KTIcon } from "helpers";
import { useTranslation } from "react-i18next";
interface Props {
  guid: string;
  deleteItemHandler: (guid: string) => Promise<void>;
}
const ConfirmationDelete: FC<Props> = ({ guid, deleteItemHandler }) => {
  const { t } = useTranslation();
  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h2 className="modal-title">{t("DELETE")}</h2>
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <KTIcon
              iconName="cross"
              iconType="outline"
              className="btn-icon fs-2 m-0"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="fs-3">{t("messages:DELETE.MASSAGE")}</div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
          >
            {t("CANCEL")}
          </button>
          <button
            type="button"
            onClick={() => deleteItemHandler(guid)}
            className="btn btn-danger"
          >
            {t("DELETE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationDelete;
