import Button from "components/base/button/Button";
import React from "react";

const CardBottomSubmitBottom = ({
  isError,
  handleSubmit,
  label = "Submit",
}: {
  isError: boolean;
  handleSubmit: () => void;
  label: string;
}) => {
  return (
    <div className="row justify-content-start ">
      <div className="col-4">
        <div className="d-grid my-4">
          <Button
            classes="btn-sm rounded-pill btn-primary"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            disabled={isError}
          >
            <span className=" text-uppercase"> {label}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardBottomSubmitBottom;
