import { WithChildren } from "helpers";
import React from "react";

const TheadRow = ({ children }: WithChildren) => {
  return (
    <thead>
      <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
        {children}
      </tr>
    </thead>
  );
};

export default TheadRow;
