import InputTextField from "components/forms/InputTextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "components/forms/FormButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { generateResetPasswordToken } from "services/authRequests";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import { ERROR_STATUS_KEY, SUCCESS_CODE } from "constant/errorCode";
interface DataProps {
  errorMsg?: string;
}
const Index = () => {
  const { t } = useTranslation();
  const validationRules = useAuthValidationRules();
  let navigate = useNavigate();
  const { state } = useLocation();
  const { data } = (state as { data?: DataProps }) || {};

  const [errorMsg, setErrorMsg] = useState<string | undefined>("");
  const [initialErrorMsg, setInitialErrorMsg] = useState<string | undefined>(
    ""
  );
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: "",
  };

  const loginSchema = Yup.object().shape({
    email: validationRules.emailValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      // try {
      generateResetPasswordToken({ userEmail: values.email })
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            setErrorMsg(response.data.statusDesc);
            setLoading(false);
          } else if (response.status === SUCCESS_CODE) {
            navigate("/forgot-link-sent", {
              state: { data: { email: values.email } },
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setStatus(t("messages:INTERNAL.SERVER.ERROR"));
        });
      // } catch (error) {
      //   setLoading(false);
      //   setStatus(t("messages:INTERNAL.SERVER.ERROR"));
      // }
    },
  }); // eslint-disable-next-line
  useEffect(() => {
    const msg = data?.errorMsg ?? "";
    setInitialErrorMsg(msg);
  });
  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="n_one_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{t("FORGOT.PASSWORD")}</h1>
        <div className="text-gray-700 fw-semibold fs-4">
          {t("messages:FORGOT.PASSWORD.DESC")}
        </div>
      </div>
      {(errorMsg || initialErrorMsg) && (
        <div className="alert alert-danger fs-3 text-center my-5" role="alert">
          {!errorMsg && initialErrorMsg}
          {errorMsg}
        </div>
      )}
      <InputTextField
        type="text"
        placeholder={t("EMAIL")}
        id="email"
        name="email"
        label={t("EMAIL")}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <div className="row">
        <FormButton
          btnLabel={t("SUBMIT")}
          formik={formik}
          isBtnDisabled={formik.isSubmitting || !formik.isValid}
          loading={loading}
        />
      </div>
      <div className="separator text-dark border-dark separator-content mt-14">
        <span className="fw-semibold fs-7">{t("OR")}</span>
      </div>
      <div className="text-gray-700 text-center fw-semibold fs-6 my-10">
        {t("messages:JUST.REMEMBER")}? <Link to="/login">{t("LOGIN")}</Link>
      </div>
    </form>
  );
};

export default Index;
