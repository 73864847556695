import { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../layout/MasterLayout";
import { Dashboard } from "../pages/dashboard/Dashboard";
import PricingPage from "../pages/pricing/PricingPage";
import Role from "../pages/user-management/role";
import Permissions from "../pages/user-management/permissions";
import Users from "../pages/user-management/users";
import PricingComparison from "../pages/pricing/PricingComparison";
import ProductSubscription from "../pages/product-subscription";
import BillingPayemnt from "../pages/billing-payment";
import MyProfile from "../pages/my-profile";
import CompanyPreferences from "../pages/company-managment/company-preferences";
import CompanyRebranding from "../pages/company-managment/company-rebranding";
import CompanyLegal from "../pages/company-managment/company-legal";
import CompanyServices from "../pages/company-managment/company-services";
import NotFound from "pages/error-page/NotFound";
import { authRoutesArray } from "constant/route/authRoutes";
import { privateRoutes } from "constant/route/privateRoutes";
const CompanyProfile = lazy(
  () => import("../pages/company-managment/company-profile")
);
const CompanyBranches = lazy(
  () => import("../pages/company-managment/company-branches")
);
const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path={privateRoutes.dashboard} element={<Dashboard />} />
        <Route
          path={privateRoutes.PricingComparison}
          element={<PricingComparison />}
        />
        <Route path={privateRoutes.Pricing} element={<PricingPage />} />
        <Route
          path={privateRoutes.pricingComparison}
          element={<PricingComparison />}
        />

        <Route path={privateRoutes.userManagementRoles} element={<Role />} />
        <Route path={privateRoutes.userManagementUsers} element={<Users />} />
        <Route
          path={privateRoutes.userManagementPermissions}
          element={<Permissions />}
        />

        <Route
          path={privateRoutes.productSubscription}
          element={<ProductSubscription />}
        />
        <Route
          path={privateRoutes.billingPayment}
          element={<BillingPayemnt />}
        />

        <Route
          path="auth/*"
          element={<Navigate to={privateRoutes.companyManagementProfile} />}
        />
        <Route
          path={privateRoutes.companyManagementProfile}
          element={<CompanyProfile />}
        />
        <Route
          path={privateRoutes.companyManagementServices}
          element={<CompanyServices />}
        />
        <Route
          path={privateRoutes.companyManagementRebranding}
          element={<CompanyRebranding />}
        />
        <Route
          path={privateRoutes.companyManagementPreferences}
          element={<CompanyPreferences />}
        />
        <Route
          path={privateRoutes.companyManagementLegal}
          element={<CompanyLegal />}
        />
        <Route
          path={privateRoutes.companyManagementCompanyBranches}
          element={<CompanyBranches />}
        />
        <Route path={privateRoutes.myProfile} element={<MyProfile />} />
        <Route path={privateRoutes.report} element={<></>} />
        <Route path={privateRoutes.helpSupport} element={<></>} />
      </Route>

      {authRoutesArray.map((path) => (
        <Route
          path={path}
          element={<Navigate to={privateRoutes.dashboard} />}
          key={path}
        />
      ))}

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { PrivateRoutes };
