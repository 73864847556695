import React, { useState, useEffect, useRef } from "react";
import "assets/css/SearchableDropdown.css";
import { useTranslation } from "react-i18next";

interface SearchableDropdownProps {
  label?: string;
  name: string;
  value: string;
  placeholder: string;
  isDisable?: boolean;
  onChange: (event: React.ChangeEvent<any>) => void;
  onBlur?: (event: React.FocusEvent<any>) => void;
  getSelectedOption?: (guid: string) => void;
  selectionOptions: { label: string; value: string }[];
  error?: boolean;
  helperText?: string | boolean;
  labelClass?: string;
  boxClass?: string;
  isVisibleSearchBox?: boolean;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  isDisable = false,
  getSelectedOption,
  selectionOptions,
  error,
  helperText,
  labelClass = " ",
  boxClass = "",
  isVisibleSearchBox = true,
  ...rest
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const filteredselectionOptions = selectionOptions.filter(
    (option) =>
      option.label.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setDropdownVisible(true);
  };

  const handleDocumentClick = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setDropdownVisible(false);
      setSearchTerm("");
    }
  };

  const handleOptionClick = (option: { label: string; value: string }) => {
    if (getSelectedOption) {
      getSelectedOption(option.value);
    }
    onChange({
      target: {
        name,
        value: option.value,
        label: option.label,
      },
    } as React.ChangeEvent<any>);
    setDropdownVisible(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    try {
      if (value) {
        let selectedData = filteredselectionOptions.filter((item) => {
          return item.value === value;
        });
        if (selectedData) {
          setSelectedLabel(selectedData[0]?.label);
        }
      } else {
        setSelectedLabel("");
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, [value]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div className={boxClass.length > 0 ? boxClass : "mb-5"} ref={dropdownRef}>
      {label && (
        <label className={"form-label text-dark fs-6 mb-0 " + labelClass}>
          {label}
        </label>
      )}
      <div className="dropdown-container w-100">
        <input
          type="text"
          placeholder={placeholder}
          name={name}
          value={selectedLabel || ""}
          onChange={() => {
            if (!isDisable) setDropdownVisible(!dropdownVisible);
          }}
          onClick={() => {
            if (!isDisable) setDropdownVisible(!dropdownVisible);
          }}
          {...rest}
          autoComplete="off"
          className={`pointer ${error ? "is-invalid " : ""} ${
            isDisable
              ? "border-0 form-control bg-field"
              : "border-1 form-select"
          }`}
          style={{ caretColor: "rgb(0 0 0 / 0%)" }}
          disabled={isDisable}
        />

        {dropdownVisible && (
          <div className="option-box bg-light" style={{ padding: "4px" }}>
            {isVisibleSearchBox && (
              <div className="w-100 p-2">
                <input
                  type="text"
                  className="w-100 form-control p-2"
                  value={searchTerm}
                  onChange={handleInputChange}
                  autoFocus={true}
                />
              </div>
            )}
            <ul className="option-list scrollbar-danger">
              {filteredselectionOptions.map((option) => (
                <li
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  onKeyDown={() => handleOptionClick(option)}
                >
                  {option.label}
                </li>
              ))}
              {filteredselectionOptions.length <= 0 && (
                <div className="text-center">
                  <li className="mt-2">{t("NO.DATA.FOUND")}</li>
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
      {error && (
        <div className="fv-plugins-message-container ">
          <div className="fv-help-block">
            <span role="alert">{helperText}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
