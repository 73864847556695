import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import CardHeader from "components/cards/CardHeader";
import Table from "components/table/Table";
import TheadRow from "components/table/TheadRow";
import { useState } from "react";
const Index = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label="Dashboard" />
        <BreadcrumbDirective label="Billing & Payment" />
      </Breadcrumbs>
      <div className="card  p-10  my-5">
        <div className="d-flex justify-content-between align-items-center  py-4 flex-wrap">
          <h3>Payment Method</h3>
          <div
            onKeyDown={() => setOpen(!open)}
            onClick={() => setOpen(!open)}
            className="rotate fs-1"
          >
            <i
              className={`fs-3 pointer fa-solid fa-angle-right ${
                open && "fa-rotate-90"
              } `}
            ></i>
          </div>
        </div>
        <div
          className={`  collapsible-content ${
            open ? "open pt-5 border-top border-primary" : "closed"
          }`}
        >
          <h3>Payment Methods will come here</h3>
        </div>
      </div>
      <div className="card p-10  my-5">
        <CardHeader>
          <h3>Users</h3>
        </CardHeader>

        <div className="card-body mt-5 p-0">
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <Table>
              <TheadRow>
                <th className="w-10px pe-2 sorting_disabled">
                  <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-kt-check="true"
                      data-kt-check-target="#subscriptions_table .form-check-input"
                      defaultValue={1}
                    />
                  </div>
                </th>
                <th className="min-w-125px sorting">Order Id</th>
                <th className="min-w-125px sorting">Product</th>
                <th className="min-w-125px sorting">Amount</th>
                <th className="min-w-125px sorting">Status</th>
                <th className="min-w-125px sorting">Date</th>
                <th className="min-w-125px sorting">Actions</th>
              </TheadRow>
              <tbody className="fw-semibold">
                <tr>
                  <td>
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                      />
                    </div>
                  </td>
                  <td>John Stark</td>
                  <td>ATS</td>
                  <td className="text-danger">₹ 999</td>
                  <td>
                    <div className="badge badge-light-warning">pendding</div>
                  </td>
                  <td data-order="2023-11-15T00:00:00+05:30">Apr 15, 2023</td>
                  <td>
                    <button className="btn btn-sm btn-light btn-active-light-primary">
                      Download
                    </button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
