import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import NiyantrasLogoWithName from "../assets/images/NiyantrasLogoWithName.webp";
import AuthImg from "../assets/images/AuthImg.png";
const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
      <div
        className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 rounded-0 card order-lg-1"
        style={{ height: "100vh", overflow: "auto" }}
      >
        <div className="d-flex flex-center flex-column flex-lg-row-fluid">
          <img src={NiyantrasLogoWithName} alt="logo" />
          <div className="w-lg-500px w-sm-500px w-100 p-1 p-md-10">
            <Outlet />
          </div>
        </div>
      </div>
      <div
        className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
        style={{ backgroundImage: `url(${AuthImg})` }}
      ></div>
    </div>
  );
};
export default AuthLayout;
