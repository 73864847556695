import AllData from "../../../assets/icons/Permission/All Data.png";
import NoData from "../../../assets/icons/Permission/NoData.png";
import RepoteesMyData from "../../../assets/icons/Permission/Repotees+My-Data.png";
import MyData from "../../../assets/icons/Permission/mydata.png";
import RepoteesData from "../../../assets/icons/Permission/RepoteesData.png";
import { FC, useState } from "react";
interface Permission {
  currentPermissions: string;
}
const PermissionDropdown: FC<Permission> = (props) => {
  const [currentPermission, setCurrentPermission] = useState(
    require(`../../../assets/icons/Permission/${props.currentPermissions}.png`)
  );
  return (
    <div>
      <div
        className="btn btn-light btn-active-light-primary btn-sm p-2 "
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <div className="img-icon">
          <img src={currentPermission} alt="AllData" />
        </div>
      </div>
      <div
        className="menu menu-sub menu-sub-dropdown menu-row menu-rounded menu-gray-600 menu-state-bg-light-primary fs-7 w-260px"
        data-kt-menu="true"
      >
        <div
          className="menu-item px-1"
          onKeyDown={() => {
            setCurrentPermission(NoData);
          }}
          onClick={() => {
            setCurrentPermission(NoData);
          }}
        >
          <div
            className="menu-link px-3"
            data-toggle="tooltip"
            data-theme="dark"
            title="No Data"
          >
            <div className="img-icon">
              <img src={NoData} alt="NoData" />
            </div>
          </div>
        </div>{" "}
        <div
          className="menu-item px-1"
          onKeyDown={() => {
            setCurrentPermission(MyData);
          }}
          onClick={() => {
            setCurrentPermission(MyData);
          }}
        >
          <div
            className="menu-link px-3"
            data-toggle="tooltip"
            data-theme="dark"
            title="My Data"
          >
            <div className="img-icon">
              <img src={MyData} alt="RepoMyDatateesData" />
            </div>
          </div>
        </div>{" "}
        <div
          className="menu-item px-1"
          onKeyDown={() => {
            setCurrentPermission(RepoteesData);
          }}
          onClick={() => {
            setCurrentPermission(RepoteesData);
          }}
        >
          <div
            className="menu-link px-3"
            data-toggle="tooltip"
            data-theme="dark"
            title="Repotees Data"
          >
            <div className="img-icon">
              <img src={RepoteesData} alt="RepoteesData" />
            </div>
          </div>
        </div>{" "}
        <div
          className="menu-item px-1"
          onKeyDown={() => {
            setCurrentPermission(RepoteesMyData);
          }}
          onClick={() => {
            setCurrentPermission(RepoteesMyData);
          }}
        >
          <div
            className="menu-link px-3"
            data-toggle="tooltip"
            data-theme="dark"
            title="Repotees My Data"
          >
            <div className="img-icon">
              <img src={RepoteesMyData} alt="RepoteesMyData" />
            </div>
          </div>
        </div>
        <div
          className="menu-item px-1"
          onKeyDown={() => {
            setCurrentPermission(AllData);
          }}
          onClick={() => {
            setCurrentPermission(AllData);
          }}
        >
          <div
            className="menu-link px-3"
            data-toggle="tooltip"
            data-theme="dark"
            title="Repotees Data"
          >
            <div className="img-icon">
              <img src={AllData} alt="RepoteesData" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionDropdown;
