import InputTextField from "components/forms/InputTextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "components/forms/FormButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetPassword,
  validateForgotPasswordToken,
} from "services/authRequests";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import { authRoutes } from "constant/route/authRoutes";

const SetNewPassword = () => {
  const location = useLocation();
  const validationRules = useAuthValidationRules();
  const queryParams = new URLSearchParams(location.search);
  const token: string | null = queryParams.get("token");

  const { t } = useTranslation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    password: "",
    confirm_password: "",
  };

  const loginSchema = Yup.object().shape({
    password: validationRules.passwordValidationSchema,
    confirm_password: validationRules.confirmPasswordValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus }) => {
      setLoading(true);
      // try {
      resetPassword({ token: token, password: values.password })
        .then((response: any) => {
          if (response.data.status === ERROR_STATUS_KEY) {
            navigate("/forgot-password");
          } else {
            navigate("/login");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
      // } catch (error) {
      //   setLoading(false);
      //   setStatus(t("messages:AUTH.LOGIN.VALIDATION"));
      // }
    },
  });
  useEffect(() => {
    try {
      if (!token) {
        navigate(authRoutes.login);
      } else {
        validateForgotPasswordToken(token)
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              navigate("/forgot-password", {
                state: { data: { errorMsg: response.data.statusDesc } },
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.error(error);
      navigate("/forgot-password");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="n_one_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{t("SET.NEW.PASSWORD")}</h1>
      </div>
      <InputTextField
        type="password"
        placeholder={t("PASSWORD")}
        id="password"
        name="password"
        label={t("PASSWORD")}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <InputTextField
        type="password"
        placeholder={t("CONFIRM.PASSWORD")}
        isvalid={
          formik.touched.confirm_password && !formik.errors.confirm_password
        }
        id="confirm_password"
        name="confirm_password"
        label={t("CONFIRM.PASSWORD")}
        value={formik.values.confirm_password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.confirm_password &&
          Boolean(formik.errors.confirm_password)
        }
        helperText={
          formik.touched.confirm_password && formik.errors.confirm_password
        }
      />
      <div className="row">
        <FormButton
          btnLabel={t("SET.NEW.PASSWORD")}
          formik={formik}
          isBtnDisabled={formik.isSubmitting || !formik.isValid}
          loading={loading}
        />
      </div>
    </form>
  );
};

export default SetNewPassword;
