import { FORGOT_PASSWORD_COUNTDOWN } from "constant/commonConstant";
import {
  ERROR_STATUS_KEY,
  INVALID_USER_EMAIL,
  MAX_RESEND_PASSWORD_RETRIES_EXCEED,
  SUCCESS_CODE,
} from "constant/errorCode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { generateResetPasswordToken } from "services/authRequests";
import CommonActivationUI, { ActivationInfoUI } from "../CommonActivationUI";
interface DataProps {
  email?: string;
}

const SendForgotPasswordLink = () => {
  const { t } = useTranslation();
  let interval: NodeJS.Timeout;
  const [errorMsg, setErrorMsg] = useState("");
  const { state } = useLocation();
  const { data } = (state as { data?: DataProps }) ?? {};
  const [attempt, setAttempt] = useState(true);
  const [countdown, setCountdown] = useState<number>(
    parseInt(
      sessionStorage.getItem("countdown") ??
        FORGOT_PASSWORD_COUNTDOWN.toString()
    )
  );
  const handleForgotPassword = () => {
    // try {
    generateResetPasswordToken({ userEmail: data?.email })
      .then((response: any) => {
        if (response.data.status === ERROR_STATUS_KEY) {
          if (response.data.statusCode === INVALID_USER_EMAIL) {
            setErrorMsg(response.data.statusDesc);
          }
          if (response.data.statusCode === MAX_RESEND_PASSWORD_RETRIES_EXCEED) {
            setAttempt(false);
            setErrorMsg(response.data.statusDesc);
          }
        } else if (response.status === SUCCESS_CODE) {
          clearInterval(interval);
          setCountdown(FORGOT_PASSWORD_COUNTDOWN);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // } catch (error) {
    //   console.error(error);
    // }
  };

  useEffect(() => {
    // eslint-disable-next-line
    interval = setInterval(() => {
      setCountdown((prevCountdown) => Math.max(0, prevCountdown - 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    sessionStorage.setItem("countdown", countdown.toString());
  }, [countdown]);
  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="n_one_login_signup_form"
    >
      <div className="text-center mb-11">
        <ActivationInfoUI title={t("messages:VERIFY.EMAIL")} />
        <div className="text-gray-700 fw-semibold fs-4">
          {t("messages:CHECK.EMAIL.TO.RESET")}{" "}
          <span className="text-primary fw-bold">{data?.email}</span>{" "}
          {t("messages:INSTRUCTIONS.RESET")}
        </div>
      </div>

      <CommonActivationUI
        handleResend={() => handleForgotPassword()}
        attempt={attempt}
        countdown={countdown}
        errorMsg={errorMsg}
      />
    </form>
  );
};

export default SendForgotPasswordLink;
