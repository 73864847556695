import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { WithChildren } from "helpers";
import { getCompanyPreferences } from "services/companyManagementRequests";

interface CompanyPreference {
  companyPreferenceGuid: string;
  dateFormat: string;
  displayLanguage: string;
  displayTimezone: string;
  productTypeGuid: string;
  productTypeName: string;
  systemMode: string;
  timeFormat: string;
}

interface CompanyManagementContextType {
  companyPreference: CompanyPreference;
  setCompanyPreference: Dispatch<SetStateAction<CompanyPreference>>;
  fetchCompanyPreferencesHandler: () => void;
}

export const initCompanyPreferenceValue = {
  companyPreferenceGuid: "",
  dateFormat: "",
  displayLanguage: "",
  displayTimezone: "",
  productTypeGuid: "",
  productTypeName: "",
  systemMode: "",
  timeFormat: "",
};

export const CompanyManagementContext =
  createContext<CompanyManagementContextType>({
    companyPreference: initCompanyPreferenceValue,
    setCompanyPreference: () => {},
    fetchCompanyPreferencesHandler: () => {},
  });

export const useCompanyManagementContext = () => {
  return useContext(CompanyManagementContext);
};

export const CompanyManagementProvider: React.FC<WithChildren> = ({
  children,
}) => {
  const [companyPreference, setCompanyPreference] = useState<CompanyPreference>(
    initCompanyPreferenceValue
  );
  const fetchCompanyPreferencesHandler = async () => {
    try {
      await getCompanyPreferences()
        .then((response: any) => {
          setCompanyPreference(response.data.data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      companyPreference,
      setCompanyPreference,
      fetchCompanyPreferencesHandler,
    }),
    // eslint-disable-next-line
    [companyPreference, setCompanyPreference]
  );
  return (
    <CompanyManagementContext.Provider value={contextValue}>
      {children}
    </CompanyManagementContext.Provider>
  );
};
