const ShowDetailsCard = () => {
  return (
    <div
      className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
      data-kt-menu="true"
    >
      <ul className="list-unstyled p-5 w-200">
        <li>
          <div className="d-flex justify-content-between gap-5 p-2">
            <span>Status</span>
            <div className="badge badge-light-success">Active</div>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between gap-5 p-2">
            <span>Subscription Plan </span>
            <div className="fw-bolder">Pro Plan</div>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between gap-5 p-2 text-start ">
            <div style={{ width: "180px" }}>Renewal Dates</div>
            <div className="fw-bolder text-end">
              Next renewal due on January 25, 2024
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default ShowDetailsCard;
