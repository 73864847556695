import ExpandableToggle from "components/base/Expandable-Table/ExpandableToggle";
import UserRoleExpandableDetails from "components/base/Expandable-Table/UserRoleExpandableDetails";
import Button from "components/base/button/Button";
import CardHeader from "components/cards/CardHeader";
import Table from "components/table/Table";
import TheadRow from "components/table/TheadRow";
import { ValidateConstant } from "constant/dashboardConstant";
import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getAssignedUserInSpecificRole } from "services/userManagementAPIs";
interface Props {
  setPageRenderSate: Dispatch<SetStateAction<string>>;
  roleGuid: string;
}
const ViewRolePage: FC<Props> = ({ roleGuid, setPageRenderSate }) => {
  const { t } = useTranslation();
  // For Now We have hardcoded data when API will create we will integrate here
  const [assignUsers, setAssignUsers] = useState<any[]>([]);

  const fetchUserRoleHandler = async (roleGuid: string) => {
    getAssignedUserInSpecificRole(roleGuid)
      .then((response: any) => {
        setAssignUsers(response.data.data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    fetchUserRoleHandler(roleGuid);
    // eslint-disable-next-line
  }, []);
  const [isExpandedDetail, setIsExpandedDetail] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    if (isExpandedDetail === index) {
      setIsExpandedDetail(null);
    } else {
      setIsExpandedDetail(index);
    }
  };

  return (
    <div>
      <CardHeader>
        <h3>{t("VIEW.ROLE.DETAILS")}</h3>
        <div className="my-4">
          <Button
            classes="btn-sm rounded-pill btn-danger"
            onKeyDown={() => {
              setPageRenderSate(ValidateConstant.INDEX);
            }}
            onClick={() => {
              setPageRenderSate(ValidateConstant.INDEX);
            }}
          >
            <span className=" text-uppercase"> {t("CANCEL")}</span>
          </Button>
        </div>
      </CardHeader>
      <div className="card-body mt-5 p-0">
        <div className="dataTables_wrapper dt-bootstrap4 no-footer">
          <Table>
            <TheadRow>
              <th className="min-w-125px sorting">{t("FIRST.NAME")}</th>
              <th className="min-w-125px sorting">{t("LAST.NAME")}</th>
              <th className="min-w-125px sorting">{t("CONTACT.NUMBER")}</th>
              <th className="min-w-125px sorting">{t("EMAIL")}</th>
              <th className="min-w-125px sorting">{t("STATUS")}</th>
            </TheadRow>
            <tbody className="fw-semibold">
              {assignUsers.map((assignUser, index) => {
                const isExpanded = isExpandedDetail === index;
                return (
                  <Fragment key={assignUser.guid}>
                    <tr>
                      <td className="d-flex align-items-center gap-4">
                        <ExpandableToggle
                          isExpanded={isExpanded}
                          toggleExpand={() => {
                            toggleExpand(index);
                          }}
                        />
                        {assignUser.firstName}
                      </td>
                      <td>{assignUser.lastName}</td>
                      <td>{assignUser.mobileNumber}</td>
                      <td>{assignUser.email}</td>
                      <td>{assignUser.status}</td>
                      {/* <td> column of action: for now we have commented if in future will need we will uncomment
                        <div>
                          <div
                            className="text-primary rounded-pill pointer"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                          >
                            <i className="fa-solid fa-ellipsis text-primary fs-2"></i>
                          </div>
                        </div>
                      </td> */}
                    </tr>
                    {isExpanded && (
                      <UserRoleExpandableDetails items={assignUser} />
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ViewRolePage;
