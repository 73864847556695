import React, { ReactNode, useEffect } from "react";
import apiInterceptor from "./apiInterceptor";
import { useAuth } from "hooks‬/Auth";

const AxiosInterceptor = ({ children }: { children: ReactNode }) => {
  const { systemLogout } = useAuth();
  useEffect(() => {
    const errInterceptor = (error: any) => {
      if (error.response && error.response.status === 401) {
        systemLogout();
      }
    };

    const interceptor = apiInterceptor.interceptors.response.use(
      (response) => response,
      errInterceptor
    );

    return () => {
      apiInterceptor.interceptors.response.eject(interceptor);
    };
  }, [systemLogout]);

  return <>{children}</>;
};

export default AxiosInterceptor;
