import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { WithChildren } from "helpers";
import {
  getAllUser,
  getRolePrivilege,
  getUserPreferences,
  getUserRole,
} from "services/userManagementAPIs";
import { UserDetails } from "helpers/models/userManagemnt";
import { initPreferenceValue } from "constant/init-values/initialValue";
import { useThemeMode } from "hooks‬/theme";
import { Preference } from "helpers/models/dashboard";
import { SelectionOption } from "helpers/models/auth_models";
import { updateColors } from "components/theme/ThemeHelpers";

interface UserManagementContextType {
  allUsers: UserDetails[];
  setAllUsers: Dispatch<SetStateAction<UserDetails[]>>;
  fetchAllUserHandler: () => void;
  fetchUserPreferencesHandler: () => void;
  fetchUserRoleHandler: () => void;
  fetchPrivilegeHandler: () => void;
  userPreference: Preference;
  setUserPreference: Dispatch<SetStateAction<Preference>>;
  setUserRole: Dispatch<SetStateAction<any[]>>;
  userRole: any[];
  product: SelectionOption[];
  privileges: any[];
  setPrivileges: Dispatch<SetStateAction<any[]>>;
}

export const UserManagementContext = createContext<UserManagementContextType>({
  allUsers: [],
  setAllUsers: () => {},
  fetchAllUserHandler: () => {},
  fetchUserPreferencesHandler: () => {},
  fetchUserRoleHandler: () => {},
  fetchPrivilegeHandler: () => {},
  userPreference: initPreferenceValue,
  setUserPreference: () => {},
  userRole: [],
  setUserRole: () => {},
  product: [],
  privileges: [],
  setPrivileges: () => {},
});

export const useUserManagementContext = () => {
  return useContext(UserManagementContext);
};

export const UserManagementProvider: React.FC<WithChildren> = ({
  children,
}) => {
  // ----useStates----
  const [allUsers, setAllUsers] = useState<UserDetails[]>([]);
  const { updateMode, updateMenuMode } = useThemeMode();
  const [userPreference, setUserPreference] =
    useState<Preference>(initPreferenceValue);
  const [userRole, setUserRole] = useState<any[]>([]);
  const [product, setProduct] = useState<SelectionOption[]>([]);
  const [privileges, setPrivileges] = useState<any[]>([]);
  // ----Get API call----
  const fetchAllUserHandler = async () => {
    getAllUser()
      .then((response: any) => {
        setAllUsers(response.data.data);
      })
      .catch((error) => console.error(error));
  };

  const fetchUserPreferencesHandler = async () => {
    try {
      await getUserPreferences()
        .then((response: any) => {
          setUserPreference(response.data.data);
          if (response.data.data.themeColor) {
            if (response.data.data.themeColor.charAt(0) === "#") {
              updateColors(response.data.data.themeColor);
            } else {
              updateColors("#" + response.data.data.themeColor);
            }
          }
          updateMenuMode(response.data.data.systemMode.toLowerCase());
          updateMode(response.data.data.systemMode.toLowerCase());
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserRoleHandler = async () => {
    getUserRole()
      .then((response: any) => {
        setUserRole(response.data.data);

        const preFormateUserRole = response.data.data.map(
          (filteredItem: any) => ({
            label: filteredItem.productType.displayName,
            value: filteredItem.productType.guid,
          })
        );

        const removeDuplicateProduct = preFormateUserRole.filter(
          (item: { value: string }, index: number, self: any[]) =>
            index === self.findIndex((t) => t.value === item.value)
        );
        setProduct(removeDuplicateProduct);
      })
      .catch((error) => console.error(error));
  };

  const fetchPrivilegeHandler = async () => {
    if (privileges.length <= 0) {
      getRolePrivilege().then((response: any) => {
        setPrivileges(response.data.data);
      });
    }
  };
  const contextValue = useMemo(
    () => ({
      allUsers,
      setAllUsers,
      fetchAllUserHandler,
      fetchUserPreferencesHandler,
      fetchUserRoleHandler,
      userPreference,
      setUserPreference,
      userRole,
      setUserRole,
      product,
      privileges,
      setPrivileges,
      fetchPrivilegeHandler,
    }),
    // eslint-disable-next-line
    [
      allUsers,
      setAllUsers,
      userPreference,
      setUserPreference,
      userRole,
      setUserRole,
      product,
      privileges,
      setPrivileges,
    ]
  );
  return (
    <UserManagementContext.Provider value={contextValue}>
      {children}
    </UserManagementContext.Provider>
  );
};
