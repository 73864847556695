import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./i18n/config";
import "./assets/fonticon/fonticon.css";
import "./assets/keenicons/duotone/style.css";
import "./assets/keenicons/outline/style.css";
import "./assets/keenicons/solid/style.css";

import "./assets/sass/style.scss";
import "./assets/sass/plugins.scss";
import "./assets/sass/style.react.scss";
import "./assets/css/rootVariables.css";
import { AppRoutes } from "./routing/AppRoutes";
import { AuthProvider } from "./hooks‬/Auth";
import "./assets/css/global.css";
import AxiosInterceptor from "services/AxiosInterceptor";
import { CommonProvider } from "hooks‬/CommonContext";
import SuccessToast from "components/toasts/SuccessToast";
import ErrorToast from "components/toasts/ErrorToast";
const queryClient = new QueryClient();
const container = document.getElementById("root");
const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REDIRECT_URI,
  REACT_APP_SUBSCRIPTION_AUDIENCE,
} = process.env;

if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <Auth0Provider
        domain={REACT_APP_AUTH0_DOMAIN ?? ""}
        clientId={REACT_APP_AUTH0_CLIENT_ID ?? ""}
        authorizationParams={{
          redirect_uri: REACT_APP_AUTH0_REDIRECT_URI,
          scope: "openid, profile, email",
          response_type: "token",
          audience: REACT_APP_SUBSCRIPTION_AUDIENCE,
        }}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <AuthProvider>
          <AxiosInterceptor>
            <CommonProvider>
              <AppRoutes />
              <SuccessToast />
              <ErrorToast />
            </CommonProvider>
          </AxiosInterceptor>
        </AuthProvider>
      </Auth0Provider>
    </QueryClientProvider>
  );
}
