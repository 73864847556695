export const privateRoutes = {
  dashboard: "/dashboard",
  PricingComparison: "/PricingComparison",
  Pricing: "/Pricing",
  userManagementRoles: "/user-management/roles",
  userManagementUsers: "/user-management/users",
  userManagementPermissions: "/user-management/permissions",
  productSubscription: "/product-subscription",
  billingPayment: "/billing-payment",
  auth: "/auth/*",
  companyManagementProfile: "/company-management/profile",
  companyManagementServices: "/company-management/services",
  companyManagementRebranding: "/company-management/rebranding",
  companyManagementPreferences: "/company-management/preferences",
  companyManagementLegal: "/company-management/legal",
  companyManagementCompanyBranches: "/company-management/company-branches",
  myProfile: "/my-profile",
  fillCompanyDetails: "/fill-company-details",
  helpSupport: "/help-support",
  report: "/report",
  pricingComparison: "pricing-comparison",
};

export const privateRoutesArray: string[] = (
  Object.keys(privateRoutes) as Array<keyof typeof privateRoutes>
).map((key) => privateRoutes[key]);
