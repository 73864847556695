import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import CardHeader from "components/cards/CardHeader";
import ProductDropdown from "components/forms/ProductDropdown";
import { getCompanyDetails } from "services/authRequests";
import {
  getOfferedServices,
  getProducts,
  getServices,
  updateOfferedServices,
} from "services/companyManagementRequests";
import { SelectionOption } from "helpers/models/auth_models";
import Button from "components/base/button/Button";
import { useTranslation } from "react-i18next";
import FormButton from "components/forms/FormButton";
import { ERROR_STATUS_KEY } from "constant/errorCode";
interface Service {
  displayName: string;
  guid: string;
  name: string;
  productTypeGuid: string;
  productTypeName: string;
}

interface CompanyOfferedService {
  companyOfferedServiceGuid: string;
  serviceGuid: string;
  serviceName: string;
  productTypeGuid: string;
  productTypeName: string;
}
interface InitialValueType {
  isReseller: string;
  servicesList: string[];
}
const Index = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [productItems, setProductItems] = useState<SelectionOption[]>([]);
  const [servicesItems, setServicesItems] = useState<Service[]>([]);
  const [iniIsReseller, setIniIsReseller] = useState<string>("");
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [currentOfferedServices, setCurrentOfferedServices] =
    useState<InitialValueType>({
      isReseller: iniIsReseller,
      servicesList: [],
    });

  const handleServices = async (guid: string) => {
    try {
      await getServices(guid)
        .then((response: any) => {
          setServicesItems(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = Yup.object().shape({
    selectedProduct: Yup.string().required(
      t("PRODUCT") + "" + t("messages:IS.REQUIRED")
    ),
  });

  const initialValues: InitialValueType = {
    isReseller: iniIsReseller,
    servicesList: [],
  };

  const servicesItemsFun = (servicesList: any) => {
    return servicesItems.map((serviceGuid: any) => {
      const service = servicesList.find(
        (item: string) => item === serviceGuid.guid
      );
      if (service) {
        return {
          serviceGuid: "" + serviceGuid.guid,
          status: "A",
        };
      } else {
        return {
          serviceGuid: "" + serviceGuid.guid,
          status: "I",
        };
      }
    });
  };

  const servicesItemsFunction = () => {
    return servicesItems.map((serviceGuid: any) => {
      const service = currentOfferedServices.servicesList.find(
        (item: string) => item === serviceGuid.guid
      );
      if (service) {
        return {
          serviceGuid: "" + serviceGuid.guid,
          status: "A",
        };
      } else {
        return {
          serviceGuid: "" + serviceGuid.guid,
          status: "I",
        };
      }
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const { isReseller, servicesList } = values;
      setLoading(true);

      const transformedServices = servicesItemsFun(servicesList);
      const makeTransformedServicesJsonForValidation = servicesItemsFunction();

      let isSendRequest = false;
      transformedServices.forEach((val) => {
        const isService = makeTransformedServicesJsonForValidation.find(
          (item) =>
            item.serviceGuid === val.serviceGuid && item.status === val.status
        );
        if (isService && !isSendRequest) {
          isSendRequest = false;
        } else {
          isSendRequest = true;
        }
      });

      if (isSendRequest) {
        updateOfferedServices({
          isReseller,
          servicesList: transformedServices,
        })
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setLoading(false);
            } else {
              setIsDisable(true);
              setSubmitting(false);
              setLoading(false);
              handleOfferedServices();
            }
          })
          .catch((error) => setLoading(false));
      } else {
        setIsDisable(true);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const productHandler = useFormik({
    initialValues: {
      selectedProduct: "",
    },
    validationSchema,
    onSubmit: (values) => {},
  });

  const handleOfferedServices = async () => {
    try {
      await getOfferedServices()
        .then((res: any) => {
          const serviceGuidArray: string[] = res.data.data.map(
            (item: CompanyOfferedService) => item.serviceGuid
          );
          const updatedInitialValues = {
            ...initialValues,
            servicesList: serviceGuidArray,
          };
          setCurrentOfferedServices(updatedInitialValues);
          formik.setValues(updatedInitialValues);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleOfferedServices();
    // eslint-disable-next-line
  }, [servicesItems]);

  useEffect(() => {
    const handleProducts = async () => {
      try {
        await getProducts()
          .then((res: any) => {
            setProductItems(
              res.data.data.map((items: any) => ({
                label: items.productTypeDTO.displayName,
                value: items.productTypeDTO.guid,
              }))
            );
            productHandler.setValues({
              selectedProduct: res.data.data[0].productTypeGuid,
            });
            handleServices(res.data.data[0].productTypeGuid);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    };

    const handleCompanyDetails = async () => {
      try {
        await getCompanyDetails()
          .then((res: any) => {
            setIniIsReseller(res.data.data.isReseller);
            formik.values.isReseller = res.data.data.isReseller;
            formik.setValues({
              ...formik.values,
              isReseller: res.data.data.isReseller,
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    };

    handleCompanyDetails();
    handleProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label={t("DASHBOARD")} />
        <BreadcrumbDirective label={t("COMPANY.SERVICES")} />
      </Breadcrumbs>

      <div className="card p-10  my-5">
        <form onSubmit={formik.handleSubmit}>
          <CardHeader>
            <h3 className="m-0">{t("COMPANY.SERVICES")}</h3>
            <div>
              {isDisable ? (
                <div className="my-4">
                  <Button
                    classes="btn-sm rounded-pill"
                    onClick={() => setIsDisable(false)}
                  >
                    <span className=" text-uppercase"> {t("EDIT")}</span>
                  </Button>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <FormButton
                    btnLabel={t("SAVE")}
                    classes="btn-sm rounded-pill my-0"
                    formik={formik}
                    isBtnDisabled={formik.isSubmitting || !formik.isValid}
                    loading={loading}
                  />{" "}
                  <div className="  my-4">
                    <Button
                      classes="btn-sm rounded-pill btn-danger"
                      onClick={() => {
                        setIsDisable(true);
                        formik.setValues(currentOfferedServices);
                      }}
                    >
                      <span className=" text-uppercase"> {t("CANCEL")}</span>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </CardHeader>

          <div className="d-flex align-items-center justify-content-end">
            <label className="me-5 text-primary-white fs-4  ">
              {t("PRODUCT")}
            </label>

            <div className="w-350px">
              <ProductDropdown
                name="selectedProduct"
                value={productHandler.values.selectedProduct}
                onChange={productHandler.handleChange}
                selectionOptions={productItems}
                getSelectedOption={handleServices}
                error={
                  productHandler.touched.selectedProduct &&
                  Boolean(productHandler.errors.selectedProduct)
                }
                helperText={
                  productHandler.touched.selectedProduct &&
                  productHandler.errors.selectedProduct
                }
              />

              {productHandler.touched.selectedProduct &&
              productHandler.errors.selectedProduct ? (
                <div className="text-danger">
                  {productHandler.errors.selectedProduct}
                </div>
              ) : null}
            </div>
          </div>
          <h2 className="fw-normal">{t("OFFERED.SERVICES")}</h2>

          {servicesItems.length > 0 &&
            servicesItems.map((items) => {
              return (
                <div
                  key={items.guid}
                  className="d-flex align-content-center my-2 fs-4"
                >
                  <input
                    type="checkbox"
                    id={items.name}
                    className="me-2 checkbox-primary"
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      const value = e.target.value;
                      if (isChecked) {
                        formik.setFieldValue("servicesList", [
                          ...formik.values.servicesList,
                          value,
                        ]);
                      } else {
                        const filteredServicesList =
                          formik.values.servicesList.filter(
                            (item) => item !== value
                          );
                        formik.setFieldValue(
                          "servicesList",
                          filteredServicesList
                        );
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={items.guid}
                    name="servicesList"
                    checked={formik.values.servicesList.includes(
                      items.guid as never
                    )}
                    disabled={isDisable}
                  />

                  <label htmlFor={items.name}>{items.displayName}</label>
                </div>
              );
            })}

          <div className="d-flex top-border-primary-white my-10 py-5 pb-0 fs-3">
            <label>{t("messages:WANT.BE.RE.SELLER")}</label>
            <div>
              <input
                type="radio"
                name="is-reseller"
                value="Y"
                checked={formik.values.isReseller === "Y"}
                onChange={() => {
                  formik.setValues({ ...formik.values, isReseller: "Y" });
                }}
                className="mx-5"
                disabled={isDisable}
              />{" "}
              {t("YES")}
              <input
                type="radio"
                name="is-reseller"
                checked={formik.values.isReseller === "N"}
                onChange={() => {
                  formik.setValues({ ...formik.values, isReseller: "N" });
                }}
                value="N"
                className="mx-5"
                disabled={isDisable}
              />{" "}
              {t("NO")}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
