import { KTIcon } from "helpers";
import PermissionDropdown from "./PermissionDropdown";
import NoData from "assets/icons/Permission/NoData.png";
import AllData from "assets/icons/Permission/All Data.png";
import RepoteesMyData from "assets/icons/Permission/Repotees+My-Data.png";
import MyData from "assets/icons/Permission/mydata.png";
import RepoteesData from "assets/icons/Permission/RepoteesData.png";
import { useState } from "react";
import ProductDropdown from "components/forms/ProductDropdown";
import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import CardHeader from "components/cards/CardHeader";

const Index = () => {
  const [product, setProduct] = useState("");
  const [formField, setFormField] = useState("");

  const roleDummy = [
    {
      id: "1",
      roleName: "Director",
    },
    {
      id: "2",
      roleName: "HR",
    },
    {
      id: "3",
      roleName: "Manager",
    },
    {
      id: "4",
      roleName: "Enterprise Bundle",
    },
  ];
  const roleForDropdown = [
    { label: "Employee", value: "Employee" },
    { label: "Asset", value: "Asset" },
    { label: "Asset Type", value: "Asset Type" },
    { label: "Candidate", value: "Candidate" },
    { label: "Benefit", value: "Benefit" },
  ];
  const productList = [
    { label: "ATS", value: "Ats" },
    { label: "HR", value: "hr" },
    { label: "CRM", value: "crm" },
  ];
  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label="Dashboard" />
        <BreadcrumbDirective label="Permissions" />
      </Breadcrumbs>
      <div className="card p-10">
        <div className="w-100">
          <CardHeader>
            <div className="d-flex gap-5 ">
              <div className=" d-flex align-items-center ">
                <label className="me-5 text-primary fs-4">Product:</label>
                <div className="w-150px">
                  <ProductDropdown
                    name="selectedProduct"
                    value={product}
                    onChange={(e) => {
                      setProduct(e.target.value);
                    }}
                    selectionOptions={productList}
                    getSelectedOption={() => console.log("OnChange")}
                  />
                </div>
              </div>
              <div className=" d-flex align-items-center ">
                <label className="me-5 text-primary fs-4">Form:</label>
                <div className="w-150px">
                  <ProductDropdown
                    name="selectedProduct"
                    value={formField}
                    onChange={(e) => {
                      setFormField(e.target.value);
                    }}
                    selectionOptions={roleForDropdown}
                    getSelectedOption={() => console.log("OnChange")}
                  />
                </div>
              </div>
            </div>

            <div className="img-icon d-flex gap-5 overflow-auto">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={NoData} alt="NoData" />
                <span>No Data</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={MyData} alt="NoData" />
                <span>My Data</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={RepoteesData} alt="NoData" />
                <span>Repotees Data</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={RepoteesMyData} alt="NoData" />
                <span>Repotees+My Data</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <img src={AllData} alt="NoData" />
                <span>All Data</span>
              </div>
            </div>
          </CardHeader>
        </div>

        <div
          id="n_one_subscriptions_table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer mt-5"
        >
          <div className="table-responsive radius-24 border border">
            <table
              className="table align-middle  fs-6 gy-5 dataTable no-footer "
              id="n_one_subscriptions_table"
            >
              <thead>
                <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0 border border-top-0 border-left-0">
                  <th
                    aria-controls="kt_subscriptions_table "
                    rowSpan={2}
                    style={{ width: "160.503px", verticalAlign: "middle" }}
                    className="text-center"
                  >
                    Roles
                  </th>
                  <th
                    className="min-w-125px sorting text-center "
                    aria-controls="kt_subscriptions_table"
                    colSpan={4}
                    style={{ width: "160.503px" }}
                  >
                    Record Permissions
                  </th>
                  <th
                    className="min-w-125px sorting"
                    aria-controls="kt_subscriptions_table"
                    style={{ width: "171.476px" }}
                  >
                    Field Permossions
                  </th>
                  <th
                    className="min-w-125px sorting"
                    aria-controls="kt_subscriptions_table"
                    colSpan={2}
                    style={{ width: "164.514px" }}
                  >
                    Action Permissions
                  </th>
                </tr>
                <tr className="border border-left-0">
                  <td
                    className="min-w-125px text-center fw-bold"
                    aria-controls="kt_subscriptions_table"
                    style={{ width: "160.503px" }}
                  >
                    Read
                  </td>
                  <th
                    className="min-w-125px text-center "
                    aria-controls="kt_subscriptions_table"
                    style={{ width: "161.476px" }}
                  >
                    Modify
                  </th>
                  <td
                    className="min-w-125px text-center fw-bold"
                    aria-controls="kt_subscriptions_table"
                    style={{ width: "164.514px" }}
                  >
                    Create
                  </td>
                  <th
                    className="min-w-125px text-center"
                    aria-controls="kt_subscriptions_table"
                    style={{ width: "160.503px" }}
                  >
                    Delete
                  </th>
                  <th
                    className="min-w-60px text-center"
                    aria-label="Actions"
                    style={{ width: "123.108px" }}
                  ></th>
                  <th
                    className="min-w-70px text-center"
                    aria-label="Actions"
                    style={{ width: "123.108px" }}
                  >
                    Import
                  </th>
                  <th
                    className="min-w-70px text-center"
                    aria-label="Actions"
                    style={{ width: "123.108px" }}
                  >
                    Export
                  </th>
                </tr>
              </thead>

              <tbody className="text-gray-600 fw-semibold">
                {roleDummy.map((role) => {
                  return (
                    <tr key={role.id} className="border border-left-0">
                      <td className="border-left-0 text-center">
                        {role.roleName}
                      </td>
                      <td className="text-center">
                        <PermissionDropdown currentPermissions="RepoteesData" />
                      </td>
                      <td className="text-center">
                        <PermissionDropdown currentPermissions="RepoteesData" />
                      </td>
                      <td className="text-center">
                        <PermissionDropdown currentPermissions="mydata" />
                      </td>

                      <td className="text-center">
                        <PermissionDropdown currentPermissions="NoData" />
                      </td>
                      <td
                        className="text-center"
                        id="n_one_drawer_form_permissions_toggle"
                      >
                        <KTIcon iconName="notepad-edit" className=" fs-1" />
                      </td>
                      <td className="text-center">
                        <KTIcon iconName="arrow-down" className=" fs-1" />
                      </td>
                      <td className="text-center">
                        <KTIcon iconName="arrow-up" className=" fs-1" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
