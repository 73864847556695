import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { PasswordMeterComponent } from "../../assets/ts/components";
import InputTextField from "../../components/forms/InputTextField";
import FormButton from "../../components/forms/FormButton";
import { signup } from "services/authRequests";
import { useTranslation } from "react-i18next";
import { useAuthValidationRules } from "helpers/validation-schema/AuthValidationRules";
import { ERROR_STATUS_KEY, SUCCESS_CODE } from "constant/errorCode";
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  companyName: "",
  confirm_password: "",
};
export function Registration() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const validationRules = useAuthValidationRules();
  const [loading, setLoading] = useState(false);

  const registrationSchema = Yup.object().shape({
    firstName: validationRules.firstNameValidationSchema,
    lastName: validationRules.lastNameValidationSchema,
    companyName: validationRules.nameValidationSchema,
    email: validationRules.emailValidationSchema,
    password: validationRules.passwordValidationSchema,
    confirm_password: validationRules.confirmPasswordValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        setLoading(true);
        const { confirm_password, ...dataToSend } = values;
        const data = dataToSend;
        await signup(data)
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setStatus(response.data.statusDesc);
              setSubmitting(false);
              setLoading(false);
            } else if (response.status === SUCCESS_CODE) {
              navigate("/email-verification", {
                state: { data: { email: data.email } },
              });
            }
          })
          .catch((error) => {
            formik.setStatus(t("messages:INTERNAL.SERVER.ERROR"));
            setSubmitting(false);
            setLoading(false);
          });
      } catch (error) {
        formik.setStatus(t("messages:INTERNAL.SERVER.ERROR"));
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="n_one_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      <div className="text-center mb-11">
        <h1 className="text-dark fw-bolder mb-3">{t("SIGN.UP")}</h1>

        <div className="text-gray-500 fw-semibold fs-6">
          {t("messages:AUTH.DESCRIPTION")}
        </div>
      </div>

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text text-center font-weight-bold">
            {formik.status}
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12 col-sm-6 col-lg-6">
          <InputTextField
            type="text"
            placeholder={t("FIRST.NAME")}
            id="firstName"
            name="firstName"
            label={t("FIRST.NAME")}
            labelClass="required"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-6 ">
          <InputTextField
            type="text"
            placeholder={t("LAST.NAME")}
            id="lastName"
            name="lastName"
            label={t("LAST.NAME")}
            labelClass="required"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </div>
      </div>

      <InputTextField
        type="text"
        placeholder={t("USER.EMAIL")}
        labelClass="required"
        id="email"
        name="email"
        label={t("USER.EMAIL")}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <InputTextField
        type="text"
        placeholder={t("COMPANY.NAME")}
        id="companyName"
        name="companyName"
        label={t("COMPANY.NAME")}
        labelClass="required"
        value={formik.values.companyName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.companyName && Boolean(formik.errors.companyName)}
        helperText={formik.touched.companyName && formik.errors.companyName}
      />

      <div className="row mb-3">
        <div className="col-12 col-sm-6 col-lg-6">
          <InputTextField
            type="password"
            placeholder={t("PASSWORD")}
            id="password"
            name="password"
            label={t("PASSWORD")}
            labelClass="required"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </div>

        <div className="col-12 col-sm-6 col-lg-6">
          <InputTextField
            type="password"
            placeholder={t("CONFIRM.PASSWORD")}
            id="confirm_password"
            name="confirm_password"
            label={t("CONFIRM.PASSWORD")}
            labelClass="required"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirm_password &&
              Boolean(formik.errors.confirm_password)
            }
            helperText={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
          />
        </div>
      </div>
      <div className="text-center">
        <FormButton
          btnLabel={t("SUBMIT")}
          formik={formik}
          isBtnDisabled={formik.isSubmitting || !formik.isValid}
          loading={loading}
        />

        {/* <div className="separator text-dark border-dark separator-content mt-14">
          <span className="fw-semibold fs-7">{t("OR")}</span>
        </div>
        <div className="d-flex justify-content-center gap-5 my-10">
          <AuthWithEmail img_url="https://steelbluemedia.com/wp-content/uploads/2019/06/new-google-favicon-512.png" /> */}
        {/* <AuthWithEmail img_url="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png" />
          <AuthWithEmail img_url="https://www.shutterstock.com/image-illustration/lahore-pakistan-may-11-2023-260nw-2301489841.jpg" /> */}
        {/* </div> */}

        <div className="text-gray-500 text-center fw-semibold fs-6">
          {t("messages:ALREADY.ACCOUNT")}? <Link to="/login">{t("LOGIN")}</Link>
        </div>
      </div>
    </form>
  );
}
