export const ValidateConstant = {
  DELETE: "Delete",
  CREATE: "Create",
  MODIFY: "Modify",
  READ: "Read",
  NONE: "None",
  EDIT: "Edit",
  CLONE: "Clone",
  MANUAL: "Manual",
  INDEX: "Index",
  VIEW_ROLE_PAGE: "ViewRolePage",
};

export const RolesConstant = {
  SALES_REPRESENTATIVE: "SALES_REPRESENTATIVE",
};

export const MyProfileConstant = {
  OVERVIEW: "Overview",
  PREFERENCES: "Preferences",
};
