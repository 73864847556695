import React, { FC, ReactNode } from "react";
interface Props {
  children: ReactNode;
  colSpan?: number;
}

const ExpandableBody: FC<Props> = ({ children, colSpan = 12 }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="collapse show">
          <div className="card card-body">{children}</div>
        </div>
      </td>
    </tr>
  );
};

export default ExpandableBody;
