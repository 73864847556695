import { ValidateConstant } from "constant/dashboardConstant";

const findRolePrivilegesWithGuid = (
  guid: string,
  userRolePrivileges: any[]
): any => {
  return userRolePrivileges.find((privilege) => privilege.guid === guid)
    ?.privilege;
};

export const findDeletePrivilege = (
  guid: string,
  userRolePrivileges: any[]
): boolean => {
  const currentPrivilege = findRolePrivilegesWithGuid(guid, userRolePrivileges);

  if (currentPrivilege.displayValue) {
    return currentPrivilege.displayValue === ValidateConstant.DELETE;
  } else {
    return false;
  }
};

export const findCreatePrivilege = (
  guid: string,
  userRolePrivileges: any[]
): boolean => {
  const currentPrivilege = findRolePrivilegesWithGuid(guid, userRolePrivileges);

  if (currentPrivilege.displayValue) {
    return (
      currentPrivilege.displayValue === ValidateConstant.DELETE ||
      currentPrivilege.displayValue === ValidateConstant.CREATE
    );
  } else {
    return false;
  }
};

export const findModifyPrivilege = (
  guid: string,
  userRolePrivileges: any[]
): boolean => {
  const currentPrivilege = findRolePrivilegesWithGuid(guid, userRolePrivileges);

  if (currentPrivilege.displayValue) {
    return (
      currentPrivilege.displayValue === ValidateConstant.DELETE ||
      currentPrivilege.displayValue === ValidateConstant.CREATE ||
      currentPrivilege.displayValue === ValidateConstant.MODIFY
    );
  } else {
    return false;
  }
};

export const findReadPrivilege = (
  guid: string,
  userRolePrivileges: any[]
): boolean => {
  const currentPrivilege = findRolePrivilegesWithGuid(guid, userRolePrivileges);
  if (currentPrivilege.displayValue) {
    return (
      currentPrivilege.displayValue === ValidateConstant.DELETE ||
      currentPrivilege.displayValue === ValidateConstant.CREATE ||
      currentPrivilege.displayValue === ValidateConstant.MODIFY ||
      currentPrivilege.displayValue === ValidateConstant.READ
    );
  } else {
    return false;
  }
};

export const findNonePrivilege = (
  guid: string,
  userRolePrivileges: any[]
): boolean => {
  const currentPrivilege = findRolePrivilegesWithGuid(guid, userRolePrivileges);

  if (currentPrivilege.displayValue) {
    return currentPrivilege.displayValue === ValidateConstant.NONE;
  } else {
    return false;
  }
};
