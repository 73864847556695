import React from "react";
import ExpandableBody from "components/base/Expandable-Table/ExpandableBody";
import ExpandableItem from "components/base/Expandable-Table/ExpandableItem";
import { useTranslation } from "react-i18next";
const UserRoleExpandableDetails = ({ items }: { items: any }) => {
  const { t } = useTranslation();
  return (
    <ExpandableBody>
      <div className="row my-1">
        <ExpandableItem title={t("ADDRESS1")} value={items.address1} />
        <ExpandableItem title={t("ADDRESS2")} value={items.address2} />
      </div>
      <div className="row my-1">
        <ExpandableItem
          title={t("COUNTRY")}
          value={items?.city?.state.country.displayName}
        />
        <ExpandableItem
          title={t("STATE")}
          value={items?.city?.state.displayName}
        />
      </div>
      <div className="row my-1">
        <ExpandableItem title={t("CITY")} value={items?.city?.displayName} />
        <ExpandableItem title={t("PIN.CODE")} value={items.pinCode} />
      </div>
    </ExpandableBody>
  );
};

export default UserRoleExpandableDetails;
