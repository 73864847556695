let root = document.documentElement;

export function getPrimaryColor() {
  let style = getComputedStyle(root);
  let color = style.getPropertyValue("--primary");
  return color;
}

export const ChangeThemeColor = (
  baseColor: string,
  activeColor: string,
  lightColor: string
) => {
  const mode: string = localStorage.getItem("theme_mode_value") ?? "light";
  colorDensityDetector(baseColor, mode);

  if (mode === "light") {
    root.style.setProperty("--primary", activeColor);
    root.style.setProperty("--primary-dark", baseColor);
  } else {
    root.style.setProperty("--primary", baseColor);
    root.style.setProperty("--primary-dark", activeColor);
  }
  root.style.setProperty("--primary-light", lightColor);
  root.style.setProperty("--secondary", lightColor);
  hexToRgb(baseColor);
};

export const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  root.style.setProperty("--bs-primary-rgb", r + "," + g + "," + b);
  return { r, g, b };
};

export const updateColors = (baseColor: string) => {
  // Calculate active and light colors
  const mode: string = localStorage.getItem("theme_mode_value") ?? "light";
  colorDensityDetector(baseColor, mode);
  const activeColor = calculateActiveColor(baseColor);
  const lightColor = calculateLightColor(baseColor);
  ChangeThemeColor(baseColor, activeColor, lightColor);
};

export const calculateActiveColor = (baseColor: string): string => {
  const baseColorRgb = hexToRgb(baseColor);
  const darkerActiveColorRgb = {
    r: Math.max(0, baseColorRgb.r - 30),
    g: Math.max(0, baseColorRgb.g - 30),
    b: Math.max(0, baseColorRgb.b - 30),
  };
  return rgbToHex(darkerActiveColorRgb);
};

const calculateLightColor = (baseColor: string): string => {
  const baseColorRgb = hexToRgb(baseColor);
  const lighterColorRgb = {
    r: Math.min(255, baseColorRgb.r + 240),
    g: Math.min(255, baseColorRgb.g + 240),
    b: Math.min(255, baseColorRgb.b + 240),
  };
  return rgbToHex(lighterColorRgb);
};

const rgbToHex = (rgb: { r: number; g: number; b: number }): string => {
  // Convert RGB to hex
  return `#${((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b)
    .toString(16)
    .slice(1)}`;
};

// ==================================================
const themeAccordingModePrimaryColor = (
  colorDensity: string,
  color: string,
  _mode: string
) => {
  if (_mode === "light") {
    if (colorDensity === "high") {
      root.style.setProperty("--primary-back-white", color);
    } else {
      root.style.setProperty("--primary-back-white", "#000");
    }
  } else {
    root.style.setProperty("--primary-back-white", "#fff");
  }
};

function themeAccordingPrimaryColor(colorDensity: string) {
  if (colorDensity === "high") {
    root.style.setProperty("--bg-primary-text", "#fff");
  } else {
    root.style.setProperty("--bg-primary-text", "#000");
  }
}

export const colorDensityDetector = (color: string, _mode: string) => {
  let c = color.substring(1);
  let rgb = parseInt(c, 16);
  let r = (rgb >> 16) & 0xff;
  let g = (rgb >> 8) & 0xff;
  let b = (rgb >> 0) & 0xff;
  let luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
  if (luma < 150) {
    themeAccordingPrimaryColor("high");
    themeAccordingModePrimaryColor("high", color, _mode);
  } else {
    themeAccordingPrimaryColor("low");
    themeAccordingModePrimaryColor("low", color, _mode);
  }
};
