import RoleCard from "./RoleCard";
import ProductDropdown from "components/forms/ProductDropdown";
import { useEffect, useState } from "react";
import Breadcrumbs from "components/base/breadcrumbs";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import CardHeader from "components/cards/CardHeader";
import Button from "components/base/button/Button";
import { useTranslation } from "react-i18next";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import UpdateRole from "./UpdateRole";
import { ValidateConstant } from "constant/dashboardConstant";
import CreateNewRole from "./CreateNewRole";
import ViewRolePage from "./ViewRolePage";
import DeleteConfirmation from "components/base/DeleteConfirmation";
import { deleteUserRole } from "services/userManagementAPIs";
import ErrorMessageBox from "components/base/ErrorMessageBox";
import { ERROR_STATUS_KEY } from "constant/errorCode";
const Index = () => {
  const { t } = useTranslation();
  const { userRole, setUserRole, product, fetchUserRoleHandler } =
    useUserManagementContext();
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [filterUserRole, setFilterUserRole] = useState<any[]>([]);
  const [pageRenderSate, setPageRenderSate] = useState(ValidateConstant.INDEX);
  const [currentUpdatingData, setCurrentUpdatingData] = useState<any>();
  const [roleGuid, setRoleGuid] = useState<string>("");
  const [deleteGuid, setDeleteGuid] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchUserRoleHandler();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (product.length > 0) {
      setSelectedProduct(product[0].value);
    }
  }, [product]);

  useEffect(() => {
    if (userRole.length > 0) {
      const filteredRoles = userRole.filter(
        (item) => item.productType.guid === selectedProduct
      );
      setFilterUserRole(filteredRoles);
    }
    // eslint-disable-next-line
  }, [selectedProduct, userRole]);

  const updateRolePrivileges = (filterUserRoleProps: any) => {
    setPageRenderSate(ValidateConstant.EDIT);
    setCurrentUpdatingData(filterUserRoleProps);
  };

  const viewRoleHandler = (roleGuid: string) => {
    setPageRenderSate(ValidateConstant.VIEW_ROLE_PAGE);
    setRoleGuid(roleGuid);
  };
  const roleDeleteHandler = async (guid: string) => {
    // try {
    await deleteUserRole(guid)
      .then((response: any) => {
        if (response.data.status === ERROR_STATUS_KEY) {
          setErrorMessage(t(response.data.statusDesc));
        } else {
          const filteredRoles = userRole.filter((item) => item.guid !== guid);
          setUserRole(filteredRoles);
          setErrorMessage("");
        }
      })
      .catch((error) => console.error(error));
    // } catch (error) {
    //   console.log(error);
    // }
  };
  return (
    <>
      <div className="container  container-xxl ">
        <Breadcrumbs>
          <BreadcrumbDirective to="/dashboard" label="Dashboard" />
          <BreadcrumbDirective label="Roles" />
        </Breadcrumbs>{" "}
        <div className="card p-10  my-5">
          {pageRenderSate === ValidateConstant.INDEX && (
            <>
              <CardHeader>
                <h3 className="m-0">{t("Roles")}</h3>
                <div className="my-4">
                  <Button
                    classes="btn-sm rounded-pill btn-primary"
                    onClick={() => {
                      setPageRenderSate("CreateRole");
                    }}
                  >
                    <span className=" text-uppercase"> {t("ADD.ROLE")}</span>
                  </Button>
                </div>
              </CardHeader>
              {errorMessage.length > 0 && (
                <ErrorMessageBox massage={errorMessage} />
              )}
              <div className=" d-flex align-items-center mb-4 ">
                <label className="me-5 text-primary-white fs-4 ">
                  {t("PRODUCT.COLON")}
                </label>
                <div className="w-250px">
                  <ProductDropdown
                    name="selectedProduct"
                    value={selectedProduct}
                    onChange={(event) => {
                      setSelectedProduct(event.target.value);
                    }}
                    selectionOptions={product}
                  />
                </div>{" "}
              </div>
              <div className="row row-cols-1  g-5 g-xl-9">
                {filterUserRole.map((item: any) => {
                  return (
                    <div
                      key={item.guid}
                      className="col-md-6 col-lg-4 col-xxl-4"
                    >
                      <RoleCard
                        roleTitle={item.displayName}
                        numberOfAssigned={item.userCount || "0"}
                        editHandler={() => {
                          updateRolePrivileges(item);
                        }}
                        deleteHandler={() => {
                          setDeleteGuid(item.guid);
                        }}
                        viewRoleHandler={() => {
                          viewRoleHandler(item.guid);
                        }}
                      />{" "}
                    </div>
                  );
                })}
              </div>
            </>
          )}

          {pageRenderSate === "CreateRole" && (
            <CreateNewRole
              selectedProduct={selectedProduct}
              setPageRenderSate={setPageRenderSate}
            />
          )}
          {pageRenderSate === ValidateConstant.EDIT && (
            <UpdateRole
              currentUpdatingData={currentUpdatingData}
              setPageRenderSate={setPageRenderSate}
            />
          )}
          {pageRenderSate === ValidateConstant.VIEW_ROLE_PAGE && (
            <ViewRolePage
              roleGuid={roleGuid}
              setPageRenderSate={setPageRenderSate}
            />
          )}
        </div>
      </div>
      <DeleteConfirmation
        guid={deleteGuid}
        deleteItemHandler={roleDeleteHandler}
      />
    </>
  );
};

export default Index;
