import React, { FC } from "react";
interface Props {
  title: string;
  onClickAction: () => void;
}
const DeleteActionMenuItem: FC<Props> = ({ title, onClickAction }) => {
  return (
    <div
      className="menu-item px-3"
      data-bs-toggle="modal"
      data-bs-target="#confirmation_delete"
      onClick={() => {
        onClickAction();
      }}
      onKeyDown={() => {
        onClickAction();
      }}
    >
      <span
        data-kt-subscriptions-table-filter="delete_row"
        className="menu-link px-3"
      >
        {title}
      </span>
    </div>
  );
};

export default DeleteActionMenuItem;
