interface JSONData {
  [key: string]: any;
}

export const areJSONsEqual = (json1: JSONData, json2: JSONData): boolean => {
  const keys1 = Object.keys(json1);
  const keys2 = Object.keys(json2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (json1[key] !== json2[key]) {
      return false;
    }
  }
  return true;
};
