const ExportModal = ({ modalId }: { modalId: string }) => {
  return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="fw-bold">Export Subscriptions</h2>

            <div className="btn btn-icon btn-sm btn-active-icon-primary">
              <i className="ki-duotone ki-cross fs-1">
                <span className="path1" />
                <span className="path2" />
              </i>{" "}
            </div>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <form
              className="form fv-plugins-bootstrap5 fv-plugins-framework"
              action="#"
            >
              <div className="text-center">
                <button type="reset" className="btn btn-light me-3">
                  Discard
                </button>
                <button type="submit" className="btn btn-primary">
                  <span className="indicator-label">Submit</span>
                  <span className="indicator-progress">
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportModal;
