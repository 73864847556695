import { WithChildren } from "helpers";
import React from "react";

const CardBody = ({ children }: WithChildren) => {
  return (
    <div className="card-body mt-5 p-0">
      <div className="dataTables_wrapper dt-bootstrap4 no-footer">
        {children}
      </div>
    </div>
  );
};

export default CardBody;
