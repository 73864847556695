import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import SearchableDropdown from "components/forms/SearchableDropdown";
import languages from "constant/json/languages.json";
import timezones from "constant/json/timezones.json";
import date_format from "constant/json/date_format.json";
import { SelectionOption } from "helpers/models/auth_models";
import moment from "moment-timezone";
import { updateUserPreferences } from "services/userManagementAPIs";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { initPreferenceValue } from "constant/init-values/initialValue";
import { useUserManagementContext } from "hooks‬/dashoard/UserManagementContext";
import ThemeColorPicker from "components/theme/ThemeColorPicker";
import PredefinedThemes from "components/theme/PredefinedThemes";
import {
  ChangeThemeColor,
  getPrimaryColor,
  updateColors,
} from "components/theme/ThemeHelpers";
import { initThemeColor, themesColorData } from "constant/commonConstant";
import CardHeader from "components/cards/CardHeader";
import CardHeaderEditSave from "components/forms/CardHeaderEditSave";
import TimeFormateSelection from "components/forms/TimeFormateSelection";
import clsx from "clsx";
const UserPreferences = () => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isSaveValue, setIsSaveValue] = useState(true);
  let isSaved = useRef(isSaveValue);
  const [currentBaseColor, setCurrentBaseColor] = useState<string | undefined>(
    initThemeColor.PRIMARY
  );
  useEffect(() => {
    setCurrentBaseColor(getPrimaryColor());
  }, []);

  const date = new Date();
  const dateFormate = date_format.map((items: any) => ({
    label: items.label + " (" + moment(date).format(items.value) + ")",
    value: items.value,
  }));

  const [languagesList, setLanguagesList] = useState<SelectionOption[]>([]);
  const [timezonesList, setTimezonesList] = useState<SelectionOption[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { userPreference, setUserPreference } = useUserManagementContext();

  const formik = useFormik({
    initialValues: initPreferenceValue,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoading(true);
      try {
        await updateUserPreferences({
          dateFormat: values.dateFormat,
          timeFormat: values.timeFormat,
          displayLanguage: values.displayLanguage,
          displayTimezone: values.displayTimezone,
          themeColor: currentBaseColor,
        })
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setErrors(response.data);
              setSubmitting(false);
              setLoading(false);
            } else {
              setUserPreference((previousData) => ({
                ...previousData,
                dateFormat: values.dateFormat,
                timeFormat: values.timeFormat,
                displayLanguage: values.displayLanguage,
                displayTimezone: values.displayTimezone,
                themeColor: currentBaseColor,
              }));
              setIsSaveValue(false);
              setLoading(false);
              setIsDisabled(true);
            }
          })
          .catch((error) => {
            setSubmitting(false);
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  function callToUpdateTheme() {
    if (userPreference.themeColor) {
      if (userPreference.themeColor.startsWith("#")) {
        updateColors(userPreference.themeColor);
      } else {
        updateColors("#" + userPreference.themeColor);
      }
    }
  }

  const cancelToUpdate = () => {
    setIsDisabled(true);
    callToUpdateTheme();
    formik.setValues({ ...userPreference });
  };

  useEffect(() => {
    const newLanguageList = languages.reduce(
      (newList: SelectionOption[], item) => {
        if (item.active === true) {
          newList.push({
            label: item.name,
            value: item.name,
          });
        }
        return newList;
      },
      []
    );
    const newTimeZonesList = timezones.reduce(
      (newList: SelectionOption[], item) => {
        if (item.isActive === true) {
          newList.push({
            label: item.displayName,
            value: item.value,
          });
        }
        return newList;
      },
      []
    );
    setTimezonesList(newTimeZonesList);
    setLanguagesList(newLanguageList);
    return () => {
      if (isSaved.current) {
        callToUpdateTheme();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    isSaved.current = isSaveValue;
  }, [isSaveValue]);

  useEffect(() => {
    if (languagesList.length > 0 && timezonesList.length > 0) {
      formik.setValues({ ...initPreferenceValue, ...userPreference });
    }
    // eslint-disable-next-line
  }, [languagesList, timezonesList]);

  return (
    <div className="card p-10  my-5">
      <form onSubmit={formik.handleSubmit}>
        <CardHeader>
          <CardHeaderEditSave
            title={t("USER.PREFERENCE")}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            cancelToUpdate={() => cancelToUpdate()}
            loading={loading}
            formik={formik}
          />
        </CardHeader>
        <br></br>
        <h3>
          {t("DISPLAY.SETTINGS")}
          {isSaved ? "True" : "False"}
        </h3>
        <div className="row my-4  fs-6  ">
          <div className="col-3 mb-4 fs-16 text-muted d-flex align-items-center">
            {t("DATE.FORMAT")}
          </div>
          <div className="col-6">
            <SearchableDropdown
              isDisable={isDisabled}
              placeholder={t("DATE.FORMAT")}
              name="dateFormat"
              value={formik.values.dateFormat}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              selectionOptions={dateFormate}
              error={
                formik.touched.dateFormat && Boolean(formik.errors.dateFormat)
              }
              helperText={formik.touched.dateFormat && formik.errors.dateFormat}
            />
          </div>
        </div>
        <div className="row my-4  fs-6  ">
          <div className="col-3 mb-4 fs-16 text-muted d-flex   align-items-center">
            {t("TIME.FORMAT")}
          </div>
          <div className="col-6">
            <div className="d-flex gap-5">
              {isDisabled ? (
                <div className="border-0 form-control text-dark bg-field">
                  {formik.values.timeFormat ? (
                    <>
                      {formik.values.timeFormat} {t("HOUR")}
                    </>
                  ) : (
                    <span className="text-muted">{t("TIME.FORMAT")}</span>
                  )}
                </div>
              ) : (
                <TimeFormateSelection formik={formik} isDisabled={isDisabled} />
              )}
            </div>
          </div>
        </div>

        <div className="row my-4  fs-6  ">
          <div className="col-3 mb-4 fs-16 text-muted d-flex align-items-center">
            {t("LANGUAGE")}
          </div>
          <div className="col-6">
            <SearchableDropdown
              isDisable={isDisabled}
              placeholder={t("LANGUAGE")}
              name="displayLanguage"
              value={formik.values.displayLanguage}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              selectionOptions={languagesList}
              error={
                formik.touched.displayLanguage &&
                Boolean(formik.errors.displayLanguage)
              }
              helperText={
                formik.touched.displayLanguage && formik.errors.displayLanguage
              }
            />
          </div>
        </div>
        <br></br>
        <h3>{t("LOCATION.SETTINGS")}</h3>
        <div className="row my-4  fs-6  ">
          <div className="col-3 mb-4 fs-16 text-muted d-flex   align-items-center">
            {t("TIME.ZONE")}
          </div>
          <div className="col-6">
            <SearchableDropdown
              isDisable={isDisabled}
              selectionOptions={timezonesList}
              placeholder={t("TIME.ZONE")}
              name="displayTimezone"
              value={formik.values.displayTimezone || ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.displayTimezone &&
                Boolean(formik.errors.displayTimezone)
              }
              helperText={
                formik.touched.displayTimezone && formik.errors.displayTimezone
              }
            />
          </div>
        </div>

        <h3>{t("THEMES")}</h3>
        <div
          className={clsx(
            "d-flex justify-content-start align-items-center my-4 gap-4 flex-wrap ",
            isDisabled && "pe-none"
          )}
        >
          <div className="mw-700px">
            <div className="p-1 radius-12 d-flex flex-wrap gap-2 justify-content-center border with-auto">
              {themesColorData.map((theme) => {
                return (
                  <PredefinedThemes
                    key={theme.baseColor}
                    currentBaseColor={currentBaseColor}
                    setCurrentBaseColor={setCurrentBaseColor}
                    baseColor={theme.baseColor}
                    activeColor={theme.activeColor}
                    lightColor={theme.lightColor}
                  />
                );
              })}
              <ThemeColorPicker
                currentBaseColor={currentBaseColor}
                setCurrentBaseColor={setCurrentBaseColor}
                id_color_type="theme_color_picker"
              />
            </div>
          </div>
          <div>
            <button
              className="btn bg-primary rounded-pill radius-12 d-flex align-items-center justify-content-center pointer"
              onClick={() => {
                ChangeThemeColor(
                  initThemeColor.PRIMARY,
                  initThemeColor.PRIMARY_DARK,
                  initThemeColor.PRIMARY_LIGHT
                );
                setCurrentBaseColor(initThemeColor.PRIMARY);
              }}
              disabled={isDisabled}
            >
              <label className="me-2 bg-primary-text">{t("RESET")}</label>
              <i
                className="fa-solid fa-arrow-rotate-left bg-primary-text fs-2"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserPreferences;
