import Tabs from "../../components/base/Tabs";
import ProfileOverview from "./ProfileOverview";
import { useState } from "react";
import profile from "assets/images/Profile.png";
import { useTranslation } from "react-i18next";
import { useAuth } from "hooks‬/Auth";
import UserPreferences from "./UserPreferences";
import { MyProfileConstant } from "constant/dashboardConstant";
const Index = () => {
  const { t } = useTranslation();
  const { userDetails, fetchUserDetail } = useAuth();
  const [activeTab, setActiveTab] = useState<string>(
    MyProfileConstant.OVERVIEW
  );

  return (
    <div className="container">
      <div className="text-primary-white">{t("SETTINGS")}</div>
      <h3 className="py-4">{t("ACCOUNT.OVERVIEW")}</h3>
      <div className="card p-10 d-flex flex-column  justify-content-between">
        <div className="d-flex flex-wrap gap-10">
          <div>
            <img
              className="rounded"
              style={{ width: "100px", height: "100px" }}
              alt="avtar"
              src={profile}
            />
          </div>
          <div className="d-flex flex-column justify-content-between">
            <div>
              <h3>
                {userDetails?.firstName} {userDetails?.lastName}
              </h3>
              <div className="d-flex flex-wrap gap-5">
                <div>
                  <i className="fa-solid fa-location-dot"></i>{" "}
                  {userDetails?.city?.displayName}
                  {userDetails?.address2}
                </div>
                <div>
                  <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                  {userDetails?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-10 position-relative fw-bold"
          style={{ top: "30px" }}
        >
          <Tabs
            value={MyProfileConstant.OVERVIEW}
            label={t("OVERVIEW")}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
          <Tabs
            value={MyProfileConstant.PREFERENCES}
            label={t("Preferences")}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
        </div>
      </div>

      {activeTab === MyProfileConstant.OVERVIEW && (
        <ProfileOverview
          userDetails={userDetails}
          userHandler={fetchUserDetail}
        />
      )}
      {activeTab === MyProfileConstant.PREFERENCES && <UserPreferences />}
    </div>
  );
};

export default Index;
