import React, { Dispatch, FC, SetStateAction } from "react";

interface Tab {
  label: string;
  value: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  activeTab: string;
}

const Tabs: FC<Tab> = ({ label, value, activeTab, setActiveTab }) => {
  return (
    <div className="pointer" onKeyDown={() => setActiveTab(value)} onClick={() => setActiveTab(value)}>
      <div className="mb-2 px-1">{label}</div>
      {activeTab === value && (
        <div className="bg-primary active-tab-border"></div>
      )}
    </div>
  );
};

export default Tabs;
