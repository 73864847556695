import clsx from "clsx";
import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: "outline" | "contained";
  size?: "sm" | "md" | "lg";
  classes?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = "contained",
  size = "medium",
  loading = false,
  classes = "",
  ...props
}) => {
  const btnClass = `btn ${classes} btn-${
    variant === "outline"
      ? "outline btn-outline-primary  btn-active-light-primary"
      : "primary"
  }  btn-${size} `;
  const { t } = useTranslation();
  const isPrimaryBg =
    variant === "contained" && !classes.includes("btn-danger");

  return (
    <button className={btnClass} {...props}>
      {loading ? (
        <span className="indicator-progress" style={{ display: "block" }}>
          {t("WAIT")}...{" "}
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        <span
          className={clsx(
            "indicator-label text-uppercase",
            isPrimaryBg && "bg-primary-text"
          )}
        >
          {" "}
          {children}
        </span>
      )}
    </button>
  );
};

export default Button;
