import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { App } from "../App";
import { AuthRoutes } from "./AuthRoutes";
import { useAuth } from "../hooks‬/Auth";
import Activation from "pages/auth/Activation";
import InitialCompanyDetailsStepper from "pages/company-managment/InitialCompanyDetailsStepper";
import { privateRoutes } from "constant/route/privateRoutes";
import { authRoutes } from "constant/route/authRoutes";
import SetupAccount from "pages/auth/SetupAccount";
import { useAuth0 } from "@auth0/auth0-react";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { currentCompany, isAuthenticatedSubscription } = useAuth();

  const { isAuthenticated, isLoading } = useAuth0();

  let element;

  if (isAuthenticated && isAuthenticatedSubscription) {
    if (currentCompany?.email) {
      element = (
        <>
          <Route path="/*" element={<PrivateRoutes />} />
          <Route index element={<Navigate to={privateRoutes.dashboard} />} />
          <Route
            path={privateRoutes.fillCompanyDetails}
            element={<Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path={authRoutes.login} element={<Navigate to="/" />} />
        </>
      );
    } else {
      element = (
        <>
          <Route
            path={authRoutes.login}
            element={<Navigate to={privateRoutes.fillCompanyDetails} />}
          />
          <Route
            path="/"
            element={<Navigate to={privateRoutes.fillCompanyDetails} />}
          />
          <Route
            path={privateRoutes.fillCompanyDetails}
            element={<InitialCompanyDetailsStepper />}
          />
        </>
      );
    }
  } else if (!isAuthenticated && !isLoading) {
    element = <Route path="/*" element={<AuthRoutes />} />;
  } else {
    element = <Route path="/*" element={<></>} />;
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          {element}
          <Route path={authRoutes.setupAccount} element={<SetupAccount />} />
          <Route path={authRoutes.userActivation} element={<Activation />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
