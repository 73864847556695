import { WithChildren } from "helpers";
import React from "react";

const CardHeader = ({ children }: WithChildren) => {
  return (
    <div className="d-flex justify-content-between align-items-center bottom-border-primary-white mb-5   flex-wrap">
      {children}
    </div>
  );
};

export default CardHeader;
