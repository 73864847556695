import Button from "components/base/button/Button";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface PropsType {
  attempt: boolean;
  countdown: number;
  errorMsg: string;
  handleResend: () => void;
}

const CommonActivationUI: FC<PropsType> = ({
  attempt,
  countdown,
  errorMsg,
  handleResend,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {attempt &&
        (countdown <= 0 ? (
          <div className="text-center fs-3 my-5 mb-10">
            <p> {t("messages:ASK.TO.RESEND")}</p>
          </div>
        ) : (
          <div className="text-center fs-3 my-5 mb-10">
            {t("messages:RESEND.IT.IN")}{" "}
            <span className="text-danger fw-bold ">{countdown}</span>{" "}
            {t("SECOND")}
          </div>
        ))}
      <div className="d-grid my-4">
        {attempt ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              handleResend();
            }}
            disabled={countdown > 0}
          >
            {t("RESEND")}
          </Button>
        ) : (
          <div className="alert alert-danger fs-3 text-center" role="alert">
            {errorMsg}
          </div>
        )}
      </div>
    </>
  );
};

interface ActivationInfoUIPropsType {
  title: string;
}
export const ActivationInfoUI: FC<ActivationInfoUIPropsType> = ({ title }) => {
  return (
    <>
      <i
        className="fa-regular text-dark fa-envelope"
        style={{ fontSize: "50px" }}
      ></i>
      <h1 className="text-dark fw-bolder mb-3">{title}</h1>
    </>
  );
};

export default CommonActivationUI;
