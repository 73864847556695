import { subscriptionApiEndpoint } from "constant/apiConstants";
import apiInterceptor from "./apiInterceptor";

export function getUser() {
  return apiInterceptor.get(`${subscriptionApiEndpoint.ENDPOINT_USER}`);
}
export function updateUser(json: any) {
  return apiInterceptor.put(`${subscriptionApiEndpoint.ENDPOINT_USER}`, json);
}

export function getAllUser() {
  return apiInterceptor.get(`${subscriptionApiEndpoint.ENDPOINT_ALL_USER}`);
}

export function createUser(json: any) {
  return apiInterceptor.post(
    `${subscriptionApiEndpoint.ENDPOINT_ALL_USER}`,
    json
  );
}

export function getUserRole() {
  return apiInterceptor.get(`${subscriptionApiEndpoint.ENDPOINT_USER_ROLE}`);
}

export function deleteUser(guid: string) {
  return apiInterceptor.delete(
    `${subscriptionApiEndpoint.ENDPOINT_ALL_USER}/${guid}`
  );
}

export function updateUserFromAllUser(json: any, guid: string) {
  return apiInterceptor.put(
    `${subscriptionApiEndpoint.ENDPOINT_ALL_USER}/${guid}`,
    json
  );
}

export function updateUserPreferences(json: any) {
  return apiInterceptor.put(
    `${subscriptionApiEndpoint.ENDPOINT_USER_PREFERENCES}`,
    json
  );
}

export function updateSystemModeUserPreferences(mode: string) {
  return apiInterceptor.put(
    `${subscriptionApiEndpoint.ENDPOINT_USER_PREFERENCES_SYSTEM_MODE}`,
    {
      systemMode: mode,
    }
  );
}
export function getUserPreferences() {
  return apiInterceptor.get(
    `${subscriptionApiEndpoint.ENDPOINT_USER_PREFERENCES}`
  );
}

export function resendUserInvitation(guid: string) {
  return apiInterceptor.get(
    `${subscriptionApiEndpoint.RESEND_USER_INVITATION}/${guid}/invite`
  );
}

export function getRolePrivilege() {
  return apiInterceptor.get(subscriptionApiEndpoint.ENDPOINT_ROLE_PRIVILEGE);
}

export function updateRolePrivilege(json: any, guid: string) {
  return apiInterceptor.put(
    `${subscriptionApiEndpoint.ENDPOINT_UPDATE_ROLE_PRIVILEGE}/${guid}/privilege`,
    json
  );
}

export function updateRoleName(roleName: string, guid: string) {
  return apiInterceptor.put(
    `${subscriptionApiEndpoint.ENDPOINT_UPDATE_ROLE_PRIVILEGE}/${guid}`,
    {
      roleName: roleName,
    }
  );
}

export function createUserRole(json: any) {
  return apiInterceptor.post(
    `${subscriptionApiEndpoint.ENDPOINT_USER_ROLE}`,
    json
  );
}

export function getAssignedUserInSpecificRole(roleGuid: string) {
  return apiInterceptor.get(
    `${subscriptionApiEndpoint.ENDPOINT_ASSIGNED_SPECIFIC_ROLE}/${roleGuid}/users`
  );
}

export function deleteUserRole(guid: string) {
  return apiInterceptor.delete(
    `${subscriptionApiEndpoint.ENDPOINT_USER_ROLE}/${guid}`
  );
}
