import React from "react";
import PermissionDropdown from "./PermissionDropdown";
import "../../../assets/css/global.css";
interface FieldPermissionProps {
  fieldPermission: {
    fieldTitle: string;
    subField: string[];
  };
}

const FieldPermissionGroup = (props: FieldPermissionProps) => {
  const [isExpand, setIsExpand] = React.useState<boolean>(false);

  //For now we hard coded. After connecting the API we will remove
  return (
    <div>
      <div
        className="d-flex justify-content-between w-100 py-1 px-5"
        style={{
          backgroundColor: "var(--bs-app-bg-color)",
          borderBottom: "1px solid #c9c9c9",
        }}
      >
        <div style={{ fontSize: "16px" }}>
          <input type="checkbox" value="" />
          <label style={{ marginLeft: "5px" }}>
            {props.fieldPermission.fieldTitle}
          </label>
        </div>
        <div className="d-flex align-items-center">
          <div className="form-check form-switch d-flex align-items-center">
            <input
              className="form-check-input h-20px w-30px"
              type="checkbox"
              role="switch"
            />
          </div>

          <div
            onKeyDown={() => setIsExpand(!isExpand)}
            onClick={() => setIsExpand(!isExpand)}
          >
            <i
              className="fa-solid fa-arrows-up-down "
              style={{ fontSize: "22px", marginLeft: "5px" }}
            />
          </div>
        </div>
      </div>

      <div className={` ${isExpand ? "" : "d-none"} `}>
        {props.fieldPermission.subField.map((field) => (
          <div
            key={field}
            className="d-flex justify-content-between my-1 px-5"
            style={{ borderBottom: "1px solid #c9c9c9" }}
          >
            <div
              className="d-flex align-items-center w-25"
              style={{ fontSize: "16px" }}
            >
              <input type="checkbox" value="" style={{ fontSize: "16px" }} />
              <label style={{ marginLeft: "5px" }}> {field}</label>
            </div>

            <PermissionDropdown currentPermissions="NoData" />

            <PermissionDropdown currentPermissions="NoData" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FieldPermissionGroup;
