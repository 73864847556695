export const authRoutes = {
  login: "/login",
  registration: "/registration",
  emailVerification: "/email-verification",
  forgotPassword: "/forgot-password",
  forgotLinkSent: "/forgot-link-sent",
  resetPassword: "/user/reset_password",
  emailVerified: "/email-verified",
  userActivation: "/activate",
  setupAccount:"/user/setup_account"
};

export const authRoutesArray: string[] = (
  Object.keys(authRoutes) as Array<keyof typeof authRoutes>
).map((key) => authRoutes[key]);
