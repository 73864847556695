import PageLoader from "components/base/PageLoader";
import Button from "components/base/button/Button";
import {
  ACTIVATION_LINK_EXPIRED,
  ERROR_STATUS_KEY,
  SUCCESS_CODE,
} from "constant/errorCode";
import { authRoutes } from "constant/route/authRoutes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { userActivation } from "services/authRequests";

const SetupAccount = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token: string | null = queryParams.get("token");
  const [isActivationLinkExpired, setIsActivationLinkExpired] = useState(false);
  const [isActivationInvalidToken, setIsActivationInvalidToken] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        await userActivation(token)
          .then((response) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setErrorMsg(response.data.statusDesc);
              if (response.data.statusCode === ACTIVATION_LINK_EXPIRED) {
                setIsActivationLinkExpired(true);
              } else {
                setIsActivationInvalidToken(true);
              }
            } else if (response.status === SUCCESS_CODE) {
              navigate(authRoutes.resetPassword + "?token=" + token, {
                replace: true,
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [token]);
  if (isActivationInvalidToken || isActivationLinkExpired) {
    return (
      <div className="w-50 accordion-button d-flex flex-column m-auto">
        <div className="expired-message text-center">
          <i
            className="fa fa-exclamation-circle text-danger"
            aria-hidden="true"
            style={{ fontSize: "94px" }}
          ></i>
        </div>
        {errorMsg && (
          <>
            <div className="expired-message text-center">
              <p className="fs-1 my-5">{errorMsg}</p>
            </div>
            {isActivationLinkExpired && (
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {t("RESEND")}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    );
  } else return <PageLoader />;
};

export default SetupAccount;
