import InputTextField from "components/forms/InputTextField";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import BreadcrumbDirective from "components/base/breadcrumbs/BreadcrumbDirective";
import Breadcrumbs from "components/base/breadcrumbs";
import { useTranslation } from "react-i18next";
import {
  getCompanyLegalDetailsField,
  getCompanyLegalDetailsValue,
  putCompanyLegalDetailsValue,
} from "services/companyManagementRequests";
import { useAuth } from "hooks‬/Auth";
import {
  Fields,
  LegalDetails,
  LegalDetailsListToSend,
  SendLegalDetail,
} from "helpers/models/dashboard";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import CardHeader from "components/cards/CardHeader";
import CardHeaderEditSave from "components/forms/CardHeaderEditSave";

const Index = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(true);
  const [fieldList, setFieldList] = useState<Fields[]>([]);
  const [detailsOfCompanyLegals, setDetailsOfCompanyLegals] = useState<
    LegalDetails[]
  >([]);

  const { t } = useTranslation();
  const { currentCompany } = useAuth();
  const initialValues: { [key: string]: string } = {};

  const companyLegalDetailsValueHandler = async () => {
    try {
      await getCompanyLegalDetailsValue()
        .then((response: any) => {
          setDetailsOfCompanyLegals(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const companyLegalDetailsFieldHandler = async () => {
    try {
      await getCompanyLegalDetailsField("" + currentCompany.countryGuid)
        .then((response: any) => {
          setFieldList(response.data.data);
          companyLegalDetailsValueHandler();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const setLegalDetailsOnForm = () => {
    const detailsMap = new Map<string, string>();

    detailsOfCompanyLegals.forEach((detail) => {
      detailsMap.set(detail.countryLegalDetailGuid, detail.displayValue);
    });

    const formInitialValues: { [key: string]: string } = {};
    fieldList.forEach((field) => {
      const displayValue = detailsMap.get(field.countryLegalDetailGuid) ?? "";
      formInitialValues[field.displayName] = displayValue;
    });

    formik.setValues(formInitialValues);
  };

  useEffect(() => {
    setLegalDetailsOnForm();
    // eslint-disable-next-line
  }, [detailsOfCompanyLegals]);

  useEffect(() => {
    companyLegalDetailsFieldHandler();
    fieldList.forEach((field) => {
      initialValues[field.displayName] = "";
    });
    // eslint-disable-next-line
  }, []);

  const validationSchemaFields: { [key: string]: Yup.StringSchema<string> } =
    {};
  fieldList.forEach((field) => {
    validationSchemaFields[field.displayName] = Yup.string()
      .required(`${field.displayName} ${t("messages:IS.REQUIRED")}`)
      .max(255, `${t("messages:MAX.CHARACTERS")} 255`);
  });
  const companyDetailSchema = Yup.object().shape(validationSchemaFields);

  const formik = useFormik({
    initialValues,
    validationSchema: companyDetailSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setLoading(true);
      setIsDisable(true);
      const legalDetailsFilterList: SendLegalDetail[] = [];
      fieldList.forEach((field, index) => {
        const legalDetail = detailsOfCompanyLegals.find(
          (detail) =>
            detail.countryLegalDetailGuid === field.countryLegalDetailGuid
        );
        if (legalDetail) {
          legalDetailsFilterList.push({
            companyLegalDetailsGuid: legalDetail.companyLegalDetailsGuid,
            countryLegalDetailGuid: fieldList[index].countryLegalDetailGuid,
            displayValue: values[field.displayName] || "",
          });
        }
        if (detailsOfCompanyLegals.length <= 0) {
          legalDetailsFilterList.push({
            countryLegalDetailGuid: fieldList[index].countryLegalDetailGuid,
            displayValue: values[field.displayName] || "",
          });
        }
      });
      const companyDetailsJson: LegalDetailsListToSend = {
        legalDetailsFilterList,
      };
      setLoading(false);
      try {
        await putCompanyLegalDetailsValue(companyDetailsJson)
          .then((response: any) => {
            if (response.data.status === ERROR_STATUS_KEY) {
              setErrors(response.data);
              setSubmitting(false);
              setLoading(false);
            } else {
              setLoading(false);
              setIsDisable(true);
              companyLegalDetailsValueHandler();
            }
          })
          .catch((error) => {
            setSubmitting(false);
            setLoading(false);
          });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  const cancelToUpdate = () => {
    setIsDisable(true);
    setLegalDetailsOnForm();
  };

  return (
    <div className="container">
      <Breadcrumbs>
        <BreadcrumbDirective to="/dashboard" label={t("DASHBOARD")} />
        <BreadcrumbDirective label={t("LEGAL.DETAILS")} />
      </Breadcrumbs>

      <div className="card p-10 my-5">
        <form onSubmit={formik.handleSubmit} noValidate>
          <CardHeader>
            <CardHeaderEditSave
              title={t("LEGAL.DETAILS")}
              isDisabled={isDisable}
              setIsDisabled={setIsDisable}
              cancelToUpdate={() => cancelToUpdate}
              loading={loading}
              formik={formik}
            />
          </CardHeader>

          <div className="row my-4 justify-content-between fs-6">
            {fieldList.map((field) => (
              <div
                key={field.displayName}
                className="col-12 col-md-6 col-xxl-5"
              >
                <div className="row mb-4">
                  <div className="col-4 mb-4 fs-16 text-muted d-flex align-items-center">
                    {field.displayName}
                  </div>
                  <div className="col-8">
                    <InputTextField
                      isDisable={isDisable}
                      type="text"
                      placeholder={`${field.displayName}`}
                      id={field.displayName}
                      name={field.displayName}
                      value={
                        formik.values[
                          field.displayName as keyof typeof formik.values
                        ]
                          ? formik.values[
                              field.displayName as keyof typeof formik.values
                            ]
                          : ""
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched[
                          field.displayName as keyof typeof formik.touched
                        ] &&
                        Boolean(
                          formik.errors[
                            field.displayName as keyof typeof formik.errors
                          ]
                        )
                      }
                      helperText={
                        formik.touched[
                          field.displayName as keyof typeof formik.touched
                        ] &&
                        formik.errors[
                          field.displayName as keyof typeof formik.errors
                        ]
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Index;
