import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useMemo,
} from "react";
import { WithChildren } from "helpers";
import { SelectionOption } from "helpers/models/auth_models";
import {
  getCountries,
  getStates,
  getCity,
  getCompanyDomain,
} from "services/authRequests";

interface LocationContextType {
  countries: SelectionOption[];
  setCountries: Dispatch<SetStateAction<SelectionOption[]>>;
  states: SelectionOption[];
  setStates: Dispatch<SetStateAction<SelectionOption[]>>;
  cities: SelectionOption[];
  setCities: Dispatch<SetStateAction<SelectionOption[]>>;
  companyDomains: SelectionOption[];
  fetchCountries: () => void;
  fetchStates: (guid: string | undefined) => void;
  fetchCities: (guid: string | undefined) => void;
  fetchCompanyDomains: () => void;
}
//  Dispatch<SetStateAction<UserDetails[]>>
export const LocationContext = createContext<LocationContextType>({
  countries: [],
  setCountries: () => {},
  states: [],
  setStates: () => {},
  cities: [],
  setCities: () => {},
  companyDomains: [],
  fetchCountries: () => {},
  fetchStates: () => {},
  fetchCities: () => {},
  fetchCompanyDomains: () => {},
});
export const useLocationContext = () => {
  return useContext(LocationContext);
};
export const LocationProvider: React.FC<WithChildren> = ({ children }) => {
  const [states, setStates] = useState<SelectionOption[]>([]);
  const [countries, setCountries] = useState<SelectionOption[]>([]);
  const [cities, setCities] = useState<SelectionOption[]>([]);
  const [companyDomains, setCompanyDomains] = useState<SelectionOption[]>([]);

  const fetchCountries = async () => {
    try {
      await getCountries()
        .then((res: any) => {
          setCountries(
            res.data.data.map((items: any) => ({
              label: items.displayName,
              value: items.guid,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchStates = async (guid: string | undefined) => {
    setStates([]);
    setCities([]);
    if (guid) {
      try {
        await getStates(guid)
          .then((res: any) => {
            setStates(
              res.data.data.map((items: any) => ({
                label: items.displayName,
                value: items.guid,
              }))
            );
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchCities = async (guid: string | undefined) => {
    if (guid) {
      setCities([]);
      try {
        await getCity(guid)
          .then((res: any) => {
            setCities(
              res.data.data.map((items: any) => ({
                label: items.displayName,
                value: items.guid,
              }))
            );
          })

          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchCompanyDomains = async () => {
    try {
      await getCompanyDomain()
        .then((response: any) => {
          const data = response.data.data.map((items: any) => ({
            label: items.displayName,
            value: items.companyDomainGuid,
          }));
          setCompanyDomains(data);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      countries,
      setCountries,
      states,
      setStates,
      cities,
      setCities,
      companyDomains,
      fetchCountries,
      fetchStates,
      fetchCities,
      fetchCompanyDomains,
    }),
    [countries, states, cities, companyDomains]
  );

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
    </LocationContext.Provider>
  );
};
