import React from "react";
import { KTIcon } from "../../helpers";

const ConditionIcon = ({ isAccess }: { isAccess: boolean }) => {
  return (
    <td className="text-center ">
      <div className="d-flex align-items-center justify-content-center">
        {isAccess ? (
          <KTIcon
            iconType="outline"
            iconName="check-circle"
            className="fs-2 text-success"
          />
        ) : (
          <KTIcon
            iconType="outline"
            iconName="cross-circle text-danger"
            className="fs-2"
          />
        )}{" "}
      </div>
    </td>
  );
};

const PricingComparison = () => {
  const accessFeature = [
    {
      title: "Employee Database",
      free: false,
      trial: true,
      professional: true,
      business: true,
      enterprice: true,
    },
    {
      title: "Multi-entity Management",
      free: false,
      trial: true,
      professional: true,
      business: true,
      enterprice: true,
    },
    {
      title: "Niyantras Vault Integration",
      free: false,
      trial: true,
      professional: true,
      business: true,
      enterprice: true,
    },
  ];
  const ComparisonJson = [
    {
      headTitle: "EMPLOYEE DATABASE MANAGEMENT",
      features: accessFeature,
    },
    {
      headTitle: "DOCUMENT MANAGEMENT",
      features: accessFeature,
    },
  ];
  return (
    <div style={{ margin: "20px" }}>
      <div className="d-flex justify-content-between align-items-end m-5">
        <div>
          <h1>Niyantras ATS-Editions & Pricing</h1>
          <span>Choose a plan that's right for your organization</span>
        </div>
        <div className="common-section-filter-inner align-items-end">
          <span>
            <input type="checkbox" id="hide_common_feature" />
          </span>{" "}
          Hide Common Features
        </div>
      </div>

      <table style={{ background: "#fff" }} className="table table-bordered">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" className="text-center">
              <div className="checkIconCom">
                <KTIcon iconName="check-circle" className="fs-2" />
              </div>
              <h3>Free</h3>
              <div className="btn btn-outline btn-outline-primary btn-active-light-primary">
                Get Start
              </div>
            </th>
            <th scope="col" className="text-center">
              <div className="checkIconCom">
                <KTIcon iconName="check-circle" className="fs-2" />
              </div>
              <h3>Trial</h3>
              <div className="btn btn-outline btn-outline-primary btn-active-light-primary">
                Select
              </div>
            </th>
            <th scope="col" className="text-center">
              <div className="checkIconCom">
                <KTIcon iconName="check-circle" className="fs-2" />
              </div>
              <h3>Professional</h3>
              <div className="btn btn-outline btn-outline-primary btn-active-light-primary">
                Select
              </div>
            </th>
            <th scope="col" className="text-center">
              <div className="checkIconCom">
                <KTIcon iconName="check-circle" className="fs-2" />
              </div>
              <h3>Business</h3>
              <div className="btn btn-outline btn-outline-primary btn-active-light-primary">
                Select
              </div>
            </th>
            <th scope="col" className="text-center">
              <div className="checkIconCom">
                <KTIcon iconName="check-circle" className="fs-2" />
              </div>
              <h3>Enterprice</h3>
              <div className="btn btn-outline btn-outline-primary btn-active-light-primary">
                Select
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={6} style={{ background: "#f4f4f4" }}>
              PRICING (Local taxes (VAT, GST, etc.) will be charged in addition
              to the prices mentioned.)
            </td>
          </tr>

          <tr>
            <th scope="row">Billed Annually</th>
            <td>Free for 5 users</td>
            <td>₹48 /user/month</td>
            <td>₹96 /user/month</td>
            <td>₹144 /user/month</td>
            <td>₹192 /user/month</td>
          </tr>
          <tr>
            <th scope="row">Billed Monthly</th>
            <td>Free for 5 users </td>
            <td>₹60 /user</td>
            <td>₹120 /user</td>
            <td>₹180 /user</td>
            <td>₹240 /user</td>
          </tr>

          {ComparisonJson.map((item) => {
            return (
              <React.Fragment key={item.headTitle}>
                <tr>
                  <td colSpan={6} style={{ background: "#f4f4f4" }}>
                    {item.headTitle}
                  </td>
                </tr>
                {item.features.map((feature) => {
                  return (
                    <tr key={feature.title}>
                      <th scope="row">{feature.title}</th>
                      <ConditionIcon isAccess={feature.free} />
                      <ConditionIcon isAccess={feature.trial} />
                      <ConditionIcon isAccess={feature.professional} />
                      <ConditionIcon isAccess={feature.business} />
                      <ConditionIcon isAccess={feature.enterprice} />
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PricingComparison;
