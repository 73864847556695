import React, { FC, useRef } from "react";
import { KTIcon } from "helpers";
import { useTranslation } from "react-i18next";
import { Modal } from "bootstrap";
interface Props {
  guid: string;
  message?: string;
  deleteItemHandler: (guid: string) => Promise<void>;
}
const DeleteConfirmation: FC<Props> = ({
  guid,
  message,
  deleteItemHandler,
}) => {
  const { t } = useTranslation();
  const confirmationModalRef = useRef<HTMLDivElement | null>(null);
  const deleteHandler = () => {
    deleteItemHandler(guid);
    if (confirmationModalRef.current) {
      const myModal = Modal.getInstance(confirmationModalRef.current);
      myModal?.hide();
    }
  };

  return (
    <div
      className="modal fade"
      tabIndex={-1}
      id="confirmation_delete"
      ref={confirmationModalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title">{t("DELETE")}</h2>
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTIcon
                iconName="cross"
                iconType="outline"
                className="btn-icon fs-2 m-0"
              />
            </div>
          </div>
          <div className="modal-body">
            <div className="fs-3">
              { message ?? t("messages:DELETE.MASSAGE")}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light"
              data-bs-dismiss="modal"
            >
              {t("CANCEL")}
            </button>
            <button
              type="button"
              onClick={() => deleteHandler()}
              className="btn btn-danger"
            >
              {t("DELETE")}
            </button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default DeleteConfirmation;
