import axios from "axios";
import {
  getAuthAccessToken,
  getSubscriptionAccessToken,
} from "helpers/AuthHelpers";

const {
  REACT_APP_URL,
  REACT_APP_CUSTOM_HEADER_KEY,
  REACT_APP_CUSTOM_HEADER_VALUE,
} = process.env;

const apiInterceptor = axios.create({
  baseURL: REACT_APP_URL ?? "",
});

apiInterceptor.interceptors.request.use(
  (config: any) => {
    const { url } = config;
    let token = "";

    if (url.startsWith("/auth")) {
      token = getAuthAccessToken();
    } else if (url.startsWith("/subscription")) {
      token = getSubscriptionAccessToken();
    }

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    // Check if custom header key and value are defined
    if (REACT_APP_CUSTOM_HEADER_KEY && REACT_APP_CUSTOM_HEADER_VALUE) {
      config.headers[REACT_APP_CUSTOM_HEADER_KEY] =
        REACT_APP_CUSTOM_HEADER_VALUE;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiInterceptor;
