import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  ReactNode,
  useMemo,
} from "react";

import * as authHelper from "../helpers/AuthHelpers";
import { getCompanyDetails } from "services/authRequests";
import { GetCompanyDetails, UserDetails } from "helpers/models/dashboard";
import { getUser } from "services/userManagementAPIs";
import { useAuth0 } from "@auth0/auth0-react";
import { ERROR_STATUS_KEY } from "constant/errorCode";
import { setCookie } from "helpers/common/genericFunction";

type WithChildren = {
  children?: ReactNode;
};
type AuthContextProps = {
  currentCompany: GetCompanyDetails;
  setCurrentCompany: Dispatch<SetStateAction<GetCompanyDetails>>;
  systemLogout: () => void;
  companyDetailFormDashboarding: () => void;
  fetchUserDetail: () => void;
  userDetails: UserDetails | undefined;
  isAuthenticatedSubscription: boolean;
  setIsAuthenticatedSubscription: Dispatch<SetStateAction<boolean>>;
};

const initCompanyDetatils = {
  address1: "",
  address2: "",
  cityGuid: "",
  cityName: "",
  companyDetailsGuid: "",
  companyDomainGuid: "",
  companyDomainName: "",
  contactNumber: "",
  countryGuid: "",
  countryName: "",
  email: "",
  isReseller: "",
  name: "",
  pinCode: "",
  stateGuid: "",
  stateName: "",
  websiteUrl: "",
};

const initAuthContextPropsState = {
  currentCompany: initCompanyDetatils,
  setCurrentCompany: () => {},
  systemLogout: () => {},
  companyDetailFormDashboarding: () => {},
  fetchUserDetail: () => {},
  userDetails: undefined,
  isAuthenticatedSubscription: false,
  setIsAuthenticatedSubscription: () => {},
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [currentCompany, setCurrentCompany] =
    useState<GetCompanyDetails>(initCompanyDetatils);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>();
  const [isAuthenticatedSubscription, setIsAuthenticatedSubscription] =
    useState(false);
  const { logout } = useAuth0();

  const systemLogout = () => {
    authHelper.removeAuth();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const companyDetailFormDashboarding = async () => {
    try {
      await getCompanyDetails()
        .then((res: any) => {
          if (res.data.status === ERROR_STATUS_KEY) {
            systemLogout();
          } else {
            setCurrentCompany(res.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const fetchUserDetail = async () => {
    try {
      await getUser()
        .then((response: any) => {
          setUserDetails(response.data.data);
          setIsAuthenticatedSubscription(true);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const contextValue = useMemo(
    () => ({
      systemLogout,
      currentCompany,
      setCurrentCompany,
      companyDetailFormDashboarding,
      fetchUserDetail,
      userDetails,
      isAuthenticatedSubscription,
      setIsAuthenticatedSubscription,
    }),
    // eslint-disable-next-line
    [
      currentCompany,
      setCurrentCompany,
      userDetails,
      setIsAuthenticatedSubscription,
    ]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { fetchUserDetail, companyDetailFormDashboarding, systemLogout } =
    useAuth();
  const {
    isLoading,
    isAuthenticated,
    getIdTokenClaims,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const didRequest = useRef(false);
  const [isStoredToken, setIsStoredToken] = useState(false);
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          if (apiToken) {
            companyDetailFormDashboarding();
            fetchUserDetail();
          } else {
            //  will add some logic
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          systemLogout();
        }
      }
      return () => (didRequest.current = true);
    };

    const hash = window.location.hash;
    if (hash) {
      const params = new URLSearchParams(hash.replace("#", ""));
      const token = params.get("access_token");
      if (token) {
        authHelper.setAuthAccessToken(token);
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
    if (authHelper.getSubscriptionAccessToken().length > 0) {
      requestUser(authHelper.getSubscriptionAccessToken());
    }
    // eslint-disable-next-line
  }, [isLoading, isAuthenticated, isStoredToken]);
  useEffect(() => {
    (async function () {
      if (isAuthenticated) {
        try {
          const claims = await getIdTokenClaims();
          const accessToken = await getAccessTokenSilently();
          authHelper.setSubscriptionAccessToken(accessToken);
          const cvalue: string = claims?.["org_id"] ?? "";
          setCookie(authHelper.ORG_KEY, cvalue);
          setIsStoredToken(true);
        } catch (error: any) {
          loginWithRedirect();
        }
      }
    })();
    // eslint-disable-next-line
  }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently]);

  return <>{children}</>;
};

export { AuthProvider, AuthInit, useAuth };
