export const RESENT_EMAIL_COUNTDOWN = 60; // this value is in seconds
export const FORGOT_PASSWORD_COUNTDOWN = 60; //this value is in seconds
export const LOGIN_PAGE_REDIRECTOR_COUNTDOWN = 10; //this value is in seconds

export const themesColorData = [
  { baseColor: "#2A007C", activeColor: "#0c005e", lightColor: "#F4EEFF" },
  { baseColor: "#7C0700", activeColor: "#590803", lightColor: "#FFE9E8" },
  { baseColor: "#7C0052", activeColor: "#630443", lightColor: "#FFE4F6" },
  { baseColor: "#007C66", activeColor: "#364f37", lightColor: "#ECFFFC" },
  { baseColor: "#8E4E1C", activeColor: "#633612", lightColor: "#FFECE0" },
  { baseColor: "#006704", activeColor: "#024d04", lightColor: "#D9EEDA" },
  { baseColor: "#525400", activeColor: "#393b01", lightColor: "#EAEBD1" },
  { baseColor: "#00546E", activeColor: "#01485e", lightColor: "#E7F9FF" },
  { baseColor: "#3E2057", activeColor: "#301745", lightColor: "#EDDCFB" },
];

export const initThemeColor = {
  PRIMARY: "#2A007C",
  PRIMARY_DARK: "#0c005e",
  PRIMARY_LIGHT: "#F4EEFF",
};
