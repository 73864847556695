import React from "react";
import { useTranslation } from "react-i18next";

const PageLoader = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <div
          className="spinner-border text-dark"
          style={{ width: "5rem", height: "5rem" }}
        >
          <span className="sr-only"> {t("LOADING")}...</span>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
