import React, {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useState,
  useCallback,
} from "react";

type WithChildren = {
  children: ReactNode;
};

interface CommonContextType {
  showSuccess: boolean;
  successMessage: string;
  showSuccessToast: (message: string) => void;
  hideSuccessToast: () => void;

  showError: boolean;
  errorMessage: string;
  showErrorToast: (message: string) => void;
  hideErrorToast: () => void;
}

export const CommonContext = createContext<CommonContextType>({
  showSuccess: false,
  successMessage: "",
  showSuccessToast: (message: string) => {},
  hideSuccessToast: () => {},

  showError: false,
  errorMessage: "",
  showErrorToast: (message: string) => {},
  hideErrorToast: () => {},
});

export const useCommonContext = () => {
  return useContext(CommonContext);
};

export const CommonProvider: React.FC<WithChildren> = ({ children }) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const showSuccessToast = useCallback((message: string) => {
    setSuccessMessage(message);
    setShowSuccess(true);
  }, []);

  const hideSuccessToast = useCallback(() => setShowSuccess(false), []);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const showErrorToast = useCallback((message: string) => {
    setErrorMessage(message);
    setShowError(true);
  }, []);

  const hideErrorToast = useCallback(() => setShowError(false), []);

  const value = useMemo(
    () => ({
      showSuccess,
      successMessage,
      showSuccessToast,
      hideSuccessToast,
      showError,
      errorMessage,
      showErrorToast,
      hideErrorToast,
    }),
    [
      showSuccess,
      successMessage,
      showError,
      errorMessage,
      showSuccessToast,
      hideSuccessToast,
      showErrorToast,
      hideErrorToast,
    ]
  );

  return (
    <CommonContext.Provider value={value}>{children}</CommonContext.Provider>
  );
};
